import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ConfigService, Configuration } from "../_config";
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'


export class BaseService {
    private version: string = "v5";
    constructor(public http: HttpClient, public configService: ConfigService ) {

    }
    

    public get(url: string) {
        let options = this.getAuthOptions();
        return this.http.get(this.buildUrl(url, this.version), options)
            .catch(this.handleError);

    }


    public post(url: string, body: any) {
        let options = this.getAuthOptions();
        return this.http.post(this.buildUrl(url, this.version), body, options)
            .catch(this.handleError);
    }


    public put(url: string, body: any) {
        let options = this.getAuthOptions();
        return this.http.put(this.buildUrl(url, this.version), body, options)
            .catch(this.handleError);
    }


    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json, text/plain, */*');
        return headers;
    }


    private getAuthOptions(){
        let headers = this.getHeaders();
        let options = { headers: headers, withCredentials: true };
        return options;
    }


    private buildUrl(url: string, version: string) {
        return this.configService.config.baseApiUrl + '/' + version + url;
    }

    
    // private extractData(res: Response) {
    //     let body = res.json();
    //     return body.data || {};
    // }


    private handleError(error: HttpErrorResponse) {
        console.log('handling error');
        console.error(error);
        try {
            return throwError(error.error || 'Server error');
        }
        catch (e) {
            var errorMessage = error.status + " " + error.statusText;

            if( error.status == 400 )
                errorMessage = error.status + " Bad Request:"  + error.toString();
            if( error.status == 401 ) 
                errorMessage = error.status + " Unauthorised request. Please logout and login again.";
            if( error.status == 403 )
                errorMessage = error.status +" Forbidden request: Please ensure that you are part of the domain group.";
            if( error.status == 404 )
                errorMessage = "Not Found 404: Requested url was not found. " + error.toString();
            if( error.status == 500 )
                errorMessage = "Server Error 500: An unexpected error occured on the server side.";
            if( error.status == 502 )
                errorMessage = "Bad Gateway 502: An error occured on the server side"
            return throwError(errorMessage);
        }
    }
} 