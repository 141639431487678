import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from "../../../_utils";
import { ConfirmationService } from 'primeng/api';
import { FilterService } from '../../shared/filter/filter.service';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { QuoteService } from '../quote.service';
import { QuoteSession } from '../quote.session';
import { _ActiveNumber } from '../../../_models/_ActiveNumber';
import { _NumberType } from '../../../_models/_NumberType';

import { QuoteComponent } from '../quote.component';

import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'app-search-table',
    templateUrl: './search-table.component.html',
    styleUrls: ['./search-table.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SearchTableComponent extends BaseComponent implements OnInit {
    @ViewChild("mainTable") public table: Table
    public _formattedNumberList: _ActiveNumber[] = [];
    public _toCopyFormattedNumberList: _ActiveNumber[] = [];
    public _filteredNumberList: _ActiveNumber[] = [];
    public globalFilterMatchStr: string[] = [];
    public globalFilterInputStr: string = "";
    public globalFilterInputStrSaved: string = "";
    private globalKeyWords: string[] = [];

    // Display boolean variables
    public displayModifiedRowDataPopup: boolean = false;
    public displayModifiedRows: boolean = false;

    // Modified data handling
    private originalRowDataList = [];
    private modifiedRowDataList: _ActiveNumber[] = [];
    public modifiedSuccessList: _ActiveNumber[] = [];

    private isTypeFilter: boolean = false;
    public typeOptions: any[] = [];
    public selectedUser: string;

    public isLoading: boolean;
    public isFiltering: boolean = false;

    public cols: any[];

    constructor(
        private _confirmationService: ConfirmationService,
        private cd: ChangeDetectorRef,
        private _filterService: FilterService,
        private _quoteSession: QuoteSession,
        private _quoteService: QuoteService,
        private _messageService: MessageService,
        public _angulartics: Angulartics2,
        public _quote: QuoteComponent
    ) {
        super();
    }


    ngOnInit() {
        this.initCols();
        this.initTypes();
        this.initTable();

        this.filterByUser();
    }


    //#region INIT
    private initCols(): void {
        this.cols = this._quoteSession.cols;
    }


    private initTypes(): void {
        this._quoteSession.typesLoaded
            .subscribe(() => {
                this.typeOptions = [];
                let tempTypeList: _NumberType[] = this._quoteSession.types;
                for (var i = 0; i < tempTypeList.length; i++) {
                    if (tempTypeList[i]["code"] == "MachineDesignCentre")
                        this.typeOptions.push({ label: "MDC", value: tempTypeList[i]["code"] });
                    else if (tempTypeList[i]["code"] == "IndustrialEngineering")
                        this.typeOptions.push({ label: "IE", value: tempTypeList[i]["code"] });
                    else
                        this.typeOptions.push({ label: tempTypeList[i]["code"], value: tempTypeList[i]["code"] });
                }
                this.typeOptions.push({ label: 'ER', value: 'Engineering Request' });
            });
    }


    private initTable(): void {
        this._quoteSession.quoteLoaded
            .subscribe(() => {
                this.isLoading = true;
                this.selectedUser = this._quoteSession.defaultUser;

                if (this._quoteSession.users == undefined) {
                    this._quoteSession.usersLoaded
                        .subscribe(() => {
                            this.getNumbers(this.selectedUser);
                        });
                } else {
                    this.getNumbers(this.selectedUser);
                }

            });

        this._quote.userName = this.selectedUser;
    }


    private getNumbers(user: string): void {
        if (!this.isValidUser(user))
            user = "";
        this._quoteService.getAllFormattedNumbers(user)
            .toPromise()
            .then(res => {
                this._formattedNumberList = this.deserialiseFormattedNumber(res);
                this._filteredNumberList = this._formattedNumberList;
                this.isLoading = false;
                this.cd.detectChanges();
                if (user == "")
                    this.selectedUser = "All Users";
                this._quoteSession.searchTableChanged.emit(this.selectedUser);
            },
                error => {
                    this.addGrowlMsg(false, error);
                    console.log(error);
                });
    }
    //#endregion INIT


    //#region REFRESH
    public onClickRefreshBtn(): void {
        this._angulartics.eventTrack.next({ action: 'Search Tab-Refresh Page Button', properties: { category: '', label: this._quoteSession.defaultUser, value: 2 } });
        if (this.modifiedRowDataList.length > 0) {
            this._confirmationService.confirm({
                message: 'Are you sure to refresh the page? You have ' + this.modifiedRowDataList.length + ' unsaved changes',
                accept: () => {
                    this._angulartics.eventTrack.next({ action: 'Search Tab-Refresh Page Accepted', properties: { category: '', label: this._quoteSession.defaultUser, value: 2 } });
                    this.refreshMainTable();
                }
            });
        }
        else {
            this.refreshMainTable();
        }

    }


    private refreshMainTable(): void {
        this.isLoading = true;
        this._quoteService.getAllFormattedNumbers(this.selectedUser)
            .subscribe(res => {
                this._formattedNumberList = this.deserialiseFormattedNumber(res);
                this.resetAllVars(false);
                if (this._formattedNumberList.length < 1) {
                    console.log("table.component.ts: refreshMainTable() -> _formattedNumberList is not populated.");
                }
                else if (this._formattedNumberList.length >= 1) {
                    this._filteredNumberList = this._formattedNumberList;
                    this.addGrowlMsg(true, "Search table has been successfully refreshed!");
                }
                this.isLoading = false;
                this.cd.detectChanges();
                this._quoteSession.searchTableChanged.emit(this.selectedUser);
            },
                err => {
                    console.log(err);
                    this.addGrowlMsg(false, err)
                });
    }


    private resetAllVars(isGlobalSearch: boolean): void {
        if (!isGlobalSearch) {
            this.globalFilterInputStr = "";
            this.globalFilterInputStrSaved = "";
        }
        this.originalRowDataList = [];
        this.modifiedRowDataList = [];
        this.globalFilterMatchStr = [];
        this.displayModifiedRows = false;
        this._quoteSession.hasModifications = false;

        this._filteredNumberList.forEach(number => {
            number.isEdited = false;
        })
    }
    //#endregion REFRESH


    //#region MODIFY
    public saveChanges(): void {
        this.isLoading = true;

        if (this._filterService.verifyMPCs(this.modifiedRowDataList, this._quoteSession.mpcs)) {
            if (this._filterService.verifyDescription(this.modifiedRowDataList, this._quoteSession.mpcs)) {
                if (this._filterService.verifyER(this.modifiedRowDataList, this._quoteSession.mpcs)) {
                    if (this.modifiedRowDataList.length > 0) {
                        this.modifiedRowDataList.forEach(rowData => {
                            if (rowData["formattedEngineeringRequestNumber"] != "" && rowData["formattedEngineeringRequestNumber"].length > 2)
                                rowData["formattedEngineeringRequestNumber"] = this.convertToNumericERNumber(rowData["formattedEngineeringRequestNumber"]);
                        });

                        this._quoteService.putUpdatedNumber(this.serialiseFormattedNumber(this.modifiedRowDataList))
                            .subscribe(
                                modifiedSuccessList => this.onSaveChangesSuccess(modifiedSuccessList),
                                error => this.onSaveChangesError(error)
                            );

                        this._angulartics.eventTrack.next({ action: 'Search Tab-Save Changes Button', properties: { category: 'Saved', label: this._quoteSession.defaultUser, value: 2 } });
                    }
                    else {
                        this._angulartics.eventTrack.next({ action: 'Search Tab-Save Changes Button', properties: { category: 'Error-No rows were modified in the table.', label: this._quoteSession.defaultUser, value: 2 } });
                        this.addGrowlMsg(false, "No rows were modified in the table.");
                        this.isLoading = false;
                    }
                }
                else {
                    this._angulartics.eventTrack.next({ action: 'Search Tab-Save Changes Button', properties: { category: 'Error-Please specify one of the ER numbers or create a new ER number.', label: this._quoteSession.defaultUser, value: 2 } });
                    this.addGrowlMsg(false, "Please specify one of the ER numbers or create a new ER number.");
                    this.isLoading = false;
                }
            }
            else {
                this._angulartics.eventTrack.next({ action: 'Search Tab-Save Changes Button', properties: { category: 'Error-Please specify a description.', label: this._quoteSession.defaultUser, value: 2 } });
                this.addGrowlMsg(false, "Please specify a description.");
                this.isLoading = false;
            }
        }
        else {
            this._angulartics.eventTrack.next({ action: 'Search Tab-Save Changes Button', properties: { category: 'Error-Please select a valid MPC from the list or create a new MPC.', label: this._quoteSession.defaultUser, value: 2 } });
            this.addGrowlMsg(false, "Please select a valid MPC from the list or create a new MPC.");
            this.isLoading = false;
        }
    }


    private onSaveChangesSuccess(modifiedSuccessList: any): void {
        this.refreshMainTable();

        this.modifiedSuccessList = this.deserialiseFormattedNumber(modifiedSuccessList);
        this.modifiedSuccessList.forEach(rowData => {
            rowData["isEdited"] = false;
        });
        if (this.modifiedSuccessList.length > 0) {
            this._angulartics.eventTrack.next({ action: 'Search Tab-Save Changes', properties: { category: (this.modifiedSuccessList.length.toString() + ' row(s) were modified successfully.'), label: this._quoteSession.defaultUser, value: 2 } });
            this.addGrowlMsg(true, this.modifiedSuccessList.length.toString() + " row(s) were modified successfully.");
            this.displayModifiedRowDataPopup = true;
        }
        else {
            this._angulartics.eventTrack.next({
                action: 'Search Tab-Save Changes', properties: { category: 'Error-There was an error whilst verifying the modified records!', label: this._quoteSession.defaultUser, value: 2 }
            });
            this.addGrowlMsg(false, "There was an error whilst verifying the modified records!");
            this.isLoading = false;
        }
    }


    private onSaveChangesError(error: string): void {
        this._angulartics.eventTrack.next({ action: 'Search Tab-Save Changes Error', properties: { category: error, label: this._quoteSession.defaultUser, value: 2 } });
        console.log(error);
        this.addGrowlMsg(false, error);
        if (error.toString().substring(3) == "403") {
            this._quoteSession.forbiddenErrorLoaded.emit();
        }
    }


    public onClickModifiedRowsBtn(): void {
        this.table.first = 0;
        this.displayModifiedRows = !this.displayModifiedRows;
        this.isFiltering = true;
        this.cd.detectChanges();
        this._angulartics.eventTrack.next({ action: 'Search Tab-Modified Rows Button', properties: { category: '', label: this._quoteSession.defaultUser, value: 2 } });
        if (this.displayModifiedRows) {
            this._filteredNumberList = this.table._value;
            this._toCopyFormattedNumberList = this._filteredNumberList;
            this._filteredNumberList = this.modifiedRowDataList;
        }
        else {
            this._filteredNumberList = this._toCopyFormattedNumberList;

            // Whenever user clicks "All Rows" button, we need to get rid of the rows with "isEdited = false" to update the modifiedRowData List.
            let indexes: number[] = [];
            for (var i = 0; i < this.modifiedRowDataList.length; i++) {
                if (!this.modifiedRowDataList[i]["isEdited"])
                    indexes.push(i);
            }

            indexes.forEach(index => {
                this.modifiedRowDataList.splice(index, 1);
            });
        }
        this.isFiltering = false;
    }
    //#endregion MODIFY


    //#region FILTER
    public onChangeTypeFilter(): void {
        this._angulartics.eventTrack.next({ action: 'Search Tab-Type Filter Changed', properties: { category: '', label: this._quoteSession.defaultUser, value: 2 } });
        this.isTypeFilter = true;
    }


    private setToOriginalRowDataList(): void {
        this.modifiedRowDataList.forEach(modifiedRowData => {
            for (var i = 0; i < this.originalRowDataList.length; i++) {
                if (modifiedRowData["numberId"] == this.originalRowDataList[i][0]) {
                    modifiedRowData["description"] = this.originalRowDataList[i][1];
                    modifiedRowData["formattedEngineeringRequestNumber"] = this.originalRowDataList[i][2];
                    modifiedRowData["majorProductCode"] = this.originalRowDataList[i][3];
                    modifiedRowData["isEdited"] = false;
                }
            }
        });
    }


    public confirmGlobalFiltering(filter: string): void {
        this._angulartics.eventTrack.next({ action: 'Search Tab-Type Filter Changed', properties: { category: filter, label: this._quoteSession.defaultUser, value: 2 } });
        if (this.modifiedRowDataList.length == 0) {
            this.globalAndFilter(filter);
        } else {
            this._confirmationService.confirm({
                message: 'Are you sure to continue filtering? You have ' + this.modifiedRowDataList.length + ' unsaved changes.',
                accept: () => {
                    this.setToOriginalRowDataList();
                    this.globalAndFilter(filter);
                }
            });
        }
    }


    private globalAndFilter(filter: string): any {
        this._angulartics.eventTrack.next({ action: 'Search Tab-Type Filter Changed', properties: { category: filter, label: this._quoteSession.defaultUser, value: 2 } });

        this.isTypeFilter = false;
        this.resetAllVars(true);
        this.globalFilterInputStrSaved = filter;
        this.isFiltering = true;

        let keywords: string[] = [];
        let tokens: string[] = filter.replace(/\s\s+/g, ' ').trim().split(' ');
        for (var i = 0; i < tokens.length; i++) {
            // Only process keywords of more than one character
            if (tokens[i].length > 1) {
                keywords.push(tokens[i]);
            }
        }

        this.globalKeyWords = keywords;
        this._quoteSession.filteringIndex = 0;
        this.table._value = this._formattedNumberList;
        this.cd.detectChanges();
        this.table.filterGlobal(keywords[0], 'contains');
        this._quoteSession.searchTableChanged.emit(this.selectedUser);
    }


    public onKeyPressGlobalFilter(event: any): void {
        if (event.key == "Enter") {
            this._angulartics.eventTrack.next({ action: 'Search Tab-Keywords filter', properties: { category: this.globalFilterInputStr, label: this._quoteSession.defaultUser, value: 2 } });

            this.confirmGlobalFiltering(this.globalFilterInputStr);
        }
    }


    public onFilterHandler(filteredValue: any) {
        if (!this.isTypeFilter) {
            if (filteredValue == null || filteredValue == undefined || filteredValue == []) {
                this.table._value = [];
                this.addGrowlMsg(false, 'There were no records under "' + this.globalFilterInputStr + '".');
            }
            else {
                this.table._value = filteredValue;
            }

            if (this._quoteSession.filteringIndex < this.globalKeyWords.length) {
                this._quoteSession.filteringIndex = this._quoteSession.filteringIndex + 1;
                this.table.filterGlobal(this.globalKeyWords[this._quoteSession.filteringIndex], 'contains');
            }
            else {
                setTimeout(() => {
                    this.isFiltering = false;
                }, 125);
            }
        }
    }


    private filterByUser(): void {
        this._quoteSession.selectedUserChanged
            .subscribe(res => {
                this.isLoading = true;
                this.selectedUser = res;
                if (!this.isValidUser(this.selectedUser))
                    this.selectedUser = "";

                this._quoteService.getAllFormattedNumbers(this.selectedUser)
                    .subscribe(
                        formattedNumberList => this.onSuccessFilterByUser(formattedNumberList),
                        error => {
                            console.log(error);
                            this.addGrowlMsg(false, error);
                        });
            });
        console.log("user2", this.selectedUser);
        this._quote.userName = this.selectedUser;
    }


    private onSuccessFilterByUser(formattedNumberList: any): void {
        var errorMsgList = [];
        this.resetAllVars(false);
        this._formattedNumberList = this.deserialiseFormattedNumber(formattedNumberList);
        this._filteredNumberList = this._formattedNumberList;
        this.isLoading = false;
        this.cd.detectChanges();
        if (this.selectedUser == "")
            this.selectedUser = "All Users";
        this._quoteSession.searchTableChanged.emit(this.selectedUser);
        errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Search results for " + this.selectedUser.toString() + " were successfully loaded." });
    }


    public highlightGlobalFilter(value): void {
        this.globalFilterMatchStr = value.replace(/\s\s+/g, ' ').trim().split(' ');
    }


    public onNgModelChange(rowData: _ActiveNumber): void {
        this._quoteSession.hasModifications = this._filterService.addRowData(rowData, this.modifiedRowDataList, this.originalRowDataList, null, null, this.displayModifiedRows);
    }


    public onRowSelect(rowData: _ActiveNumber): void {
        this._filterService.addOriginalRowData(rowData, this.originalRowDataList);
    }
    //#endregion FILTER


    //#region Helper Methods
    public showNumberHistory(rowData: _ActiveNumber): void {
        this._quoteSession.numberHistoryRequested.emit(rowData);
    }


    public countNumPages(numRecords: number): number {
        return Math.ceil(numRecords / 250);
    }


    private isValidUser(user: string): boolean {
        let isValid: boolean = false;
        if (user == "") {
            isValid = true;
        } else {

            if (user != null)
            {
                let users: string[] = this._quoteSession.users;
                for (var i = 0; i < users.length; i++) {
                    if (user.toLowerCase().indexOf(users[i].toLowerCase()) == 0) {
                        isValid = true;
                        break;
                    }
                }
            }
            
        }
        return isValid;
    }


    public convertToLocalTime(createdUtc: string): string {
        return this.convertToLocalTimeString(createdUtc, false, "second");
    }


    private addGrowlMsg(success: boolean, message: string): void {
        this._messageService.clear();
        if (success)
            this._messageService.add({ severity: 'success', summary: 'Success', detail: message });
        else
            this._messageService.add({ severity: 'error', summary: 'Failed', detail: message });
    }


    public containsStr(toMatch): boolean {
        let toReturn: boolean = false;
        let toCompare: string = "";
        if (toMatch != undefined)
            toCompare = toMatch.toLowerCase();

        for (var i = 0; i < this.globalFilterMatchStr.length; i++) {
            if (this.globalFilterMatchStr[i] != undefined && this.globalFilterMatchStr[i].length >= 2 && toCompare.includes(this.globalFilterMatchStr[i].toLowerCase())) {
                toReturn = true;
                break;
            }
        }
        return toReturn;
    }
    //#endregion Helper Methods
} 