<!-- Single Addition Success Popup -->
<p-dialog header="New Number Generated Successfully" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" [style]="{'width':'600px'}" autoLayout="true">
  <div class="row">
      <div class="col-xs-12">
          <label class="modal-header">{{singleNumber}}</label>
      </div>
  </div>

  <div class="row">
      <div class="col-xs-12">
          <button pButton class="basic-button" icon="fa fa-copy" (click)="copySingleNumber()" label="Copy Number"></button>
      </div>
  </div>
</p-dialog>
