//import { PageScrollConfig } from 'ngx-page-scroll';
import { Easing } from "./easing";
export class PageScroll {
  //PageScrollConfig is a singleton that controls the defaults for all the app. it can be overidden each page
  // public static setDefaults(): void {
  //   PageScrollConfig.defaultDuration = 600;
  //   PageScrollConfig.defaultScrollOffset = 75;
  //   PageScrollConfig.defaultInterruptible = false;
  //   PageScrollConfig.defaultEasingLogic = {
  //     ease: (t: number, b: number, c: number, d: number): number => {
  //       return Easing.easeInOutQuad(t, b, c, d);
  //     }
  //   };
  // }
} 