import { Component, OnInit, Input  } from '@angular/core';
import { BaseComponent } from "app/_utils";
import * as packageJSON from 'app/../../package.json';

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
      './footer.component.css'
  ]
})

export class FooterComponent extends BaseComponent  {
    private package = <any>packageJSON;

    public version = '0.1.0-0';

    constructor() {
        super(); 

        this.version = this.package.version;
    }
}
