import { Component, OnInit, AfterViewInit, Input, ChangeDetectionStrategy, ChangeDetectorRef  } from '@angular/core';
import { BaseComponent } from "../../../_utils";
import { ConfigService } from "../../../_config";

@Component({
  selector: 'layout-environment',
  templateUrl: './environment.component.html',
  styleUrls: [
      './environment.component.css'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentComponent extends BaseComponent implements OnInit, AfterViewInit  {

    constructor(public cs: ConfigService, private cd: ChangeDetectorRef) {
        super();
    }
   
    ngOnInit() {
      this.cs.changed.subscribe(() => {
          this.cd.detectChanges();
      });
    }
 
    ngAfterViewInit() {
        //TODO: ngIf is causing a dev mode error
        this.cd.detectChanges();
    }

}
