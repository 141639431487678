export class Easing {

  public static easeInOutExpo(t: number, b: number, c: number, d: number): number {
    if (t === 0) return b;
    if (t === d) return b + c;
    if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
  }

  public static easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  public static easeOutQuart(t: number, b: number, c: number, d: number): number {
    t /= d;
    t--;
    return -c * (t * t * t * t - 1) + b;
  }

  //circular easing in /out - acceleration until halfway, then deceleration
  public static easeInOutCirc(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
    t -= 2;
    return c / 2 * (Math.sqrt(1 - t * t) + 1) + b;
  }





} 
