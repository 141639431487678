import { _ActiveNumber } from '../_models/_ActiveNumber';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';


export class BaseComponent {

    constructor() {
    }

    public loadSelectItems(source: Object[]): SelectItem[] {
        let opts: SelectItem[] = [];

        source.map((x: any) => opts.push({ label: x.label, value: x.value || x.label }));
        return opts;
    }

    public add(form: FormGroup, id: string, value?: any, validators?: any): FormControl {
        let control = new FormControl(value, validators)
        form.addControl(id, control);
        return control;
    }

    public clearValidators(source: FormGroup): void {
        Object.keys(source.controls).forEach(key => {
            this.clearValidator(source.get(key));
        });
    }

    public clearValidator(source: AbstractControl): void {
        source.clearValidators();
        source.updateValueAndValidity();
    }


    public convertToLocalTimeString(dateStr: string, isOneDay: Boolean, version: string): string {
        var date = new Date(dateStr);
        let toReturn: string = "";
        if( dateStr != null && dateStr != "" ){
            var years = date.getFullYear().toString();
            var hours = date.getHours().toLocaleString();
            var minutes = date.getMinutes().toLocaleString();
            var seconds = date.getSeconds().toLocaleString();
            var days = date.getDate().toLocaleString();
            var month = (date.getMonth() + 1 ).toLocaleString();
            if( hours.length == 1 )
                hours = "0" + hours;
            if( minutes.length == 1 )
                minutes = "0" + minutes;
            if( seconds.length == 1 )
                seconds = "0" + seconds;
            if( month.length == 1 )
                month = "0" + month;
            if( days.length == 1 )
                days = "0" + days;
    
            if( version == "minute"){
                if( isOneDay )
                    toReturn =  hours + ":" + minutes;
                else
                    toReturn = month + "-" + days + ", " + hours + ":" + minutes;
            }
            else if( version == "second")
                toReturn = years + "-" + month + "-" + days + " " + hours + ":" + minutes + ":" + seconds;
            if( years == "1")
                toReturn = "";
        }
        return toReturn;
    }


    public convertToNumericERNumber(formattedEngineeringRequestNumber: string): string {
        return formattedEngineeringRequestNumber.substring(2);
    }


    public copyToClipboard(toCopy: string): void {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = toCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    
    public deserialiseFormattedNumber(formattedNumbers: any): _ActiveNumber [] {
        return JSON.parse( JSON.stringify(formattedNumbers), function(k, v){
                if( k === "f" )
                    this.formattedNumber = v;
                else if( k === 'ni' )
                    this.numberId = v;
                else if( k === 'c' ) 
                    this.code = v;
                else if( k === 'n' )
                    this.number = v;
                else if( k === 'd' )
                    this.description = v;
                else if( k === 'er' )
                    this.formattedEngineeringRequestNumber = v;
                else if( k === 'm' )
                    this.majorProductCode = v;
                else if( k === 'cb' )
                    this.createdBy = v;
                else if( k === 'cu' )
                    this.createdUtc = v;
                else if( k === 's' )
                    this.serialNumber = v;
                else if( k === 'id' )
                    this.isDuplicate = v;
                else if( k === 'it' ) 
                    this.isTransferred = v;
                else return v;
            });
    }


    public serialiseFormattedNumber(formattedNumbers: any): _ActiveNumber [] {
        return JSON.parse( JSON.stringify(formattedNumbers), function(k, v){
                if( k === "formattedNumber" )
                    this.f = v;
                else if( k === 'numberId' )
                    this.ni = v;
                else if( k === 'code' ) 
                    this.c = v;
                else if( k === 'number' )
                    this.n = v;
                else if( k === 'description' )
                    this.d = v;
                else if( k === 'formattedEngineeringRequestNumber' )
                    this.er = v;
                else if( k === 'majorProductCode' )
                    this.m = v;
                else if( k === 'createdBy' )
                    this.cb = v;
                else if( k === 'createdUtc' )
                    this.cu = v;
                else if( k === 'serialNumber' )
                    this.s = v;
                else if( k === 'isDuplicate' )
                    this.id = v;
                else if( k === 'isTransferred' ) 
                    this.it = v;
                else return v;
            });
    }
} 