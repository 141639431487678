import { BaseComponent } from "../../../../_utils";
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuoteService } from '../../quote.service';
import { QuoteSession } from '../../quote.session';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { _ActiveNumber } from '../../../../_models/_ActiveNumber';
import { _NewNumberRequest } from '../../../../_models/_NewNumberRequest';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'app-engineering-request-addition',
    templateUrl: './engineering-request-addition.component.html',
    styleUrls: ['./engineering-request-addition.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class EngineeringRequestAdditionComponent extends BaseComponent implements OnInit {
    //Create New ER Number
    private newERNumber: _ActiveNumber;
    public newERNumberStr: string;
    public newERNumberDescription: string = "";

    public displayCreateER: boolean = false;

    constructor(
        private _quoteSession: QuoteSession,
        private _quoteService: QuoteService,
        private _messageService: MessageService,
        public _angulartics: Angulartics2
    ) {
        super();
    }


    ngOnInit() {
        this._quoteSession.quoteLoaded
            .subscribe(() => {
                this.displayCreateER = false;
            });

        this.onERNumberRequested();
    }


    //#region INIT
    private onERNumberRequested(): void {
        this._quoteSession.newERNumberRequested
            .subscribe(() => {
                this.resetVars();
            },
                error => {
                    this.addGrowlMsg(false, error);
                });
    }
    //#endregion INIT


    //#region GET NEW ER
    public getNewER(): void {
        this.newERNumber = null;
        
        if (this.validateInput()) {
            this.displayCreateER = false;
            let request: _NewNumberRequest = new _NewNumberRequest("Engineering Request", 1, "", "", this.newERNumberDescription, "");
            this._quoteService.putNewNumber(request)
                .subscribe(
                    newERNumber => this.onGetNewERSuccess(newERNumber),
                    error => this.onGetNewERError(error)
                );
        }
    }


    private onGetNewERSuccess(newERNumber: any): void {
        var deserailisedERNumber = this.deserialiseFormattedNumber(newERNumber);
        if (deserailisedERNumber.length == 1) {
            this.newERNumber = deserailisedERNumber[0];
            this.newERNumberStr = this.newERNumber["formattedNumber"];
            this._quoteService.getAllERNumbers()
                .subscribe(res => {
                    var erNumberList = this.deserialiseFormattedNumber(res);
                    if (erNumberList) {
                        this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New ER', properties: { category: 'Success', label: this._quoteSession.defaultUser, value: 1 } });
                        for (var i = 0; i < erNumberList.length; i++) {
                            if (erNumberList[i]["formattedNumber"] == this.newERNumber["formattedNumber"]) {
                                this._quoteSession.selectedERNumber = erNumberList[i]["formattedNumber"];
                                this.addGrowlMsg(true, "New ER number " + erNumberList[i]["formattedNumber"] + " was successfully created.");
                                this._quoteSession.newERNumberLoaded.emit();
                                break;
                            }
                        }
                    }
                    else {
                        this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New ER', properties: { category: 'Error-An unexpected exception occurred on the server whilst processing your request.', label: this._quoteSession.defaultUser, value: 1 } });
                        this.addGrowlMsg(false, "An unexpected exception occurred on the server whilst processing your request.");
                    }
                });
        }
        else {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New ER', properties: { category: 'Error-There was an error getting a new ER number.', label: this._quoteSession.defaultUser, value: 1 } });

            console.log("number-addition-component.ts: showCreateER() -> There was an error whilst getting a new ER number.");
            this.addGrowlMsg(false, "There was an error getting a new ER number.");
        }
    }


    private onGetNewERError(error: string): void {
        this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New ER', properties: { category: 'Error-'+error, label: this._quoteSession.defaultUser, value: 1 } });
        console.log(error);
        this.addGrowlMsg(false, error);
        if (error.toString().substring(3) == "403")
            this._quoteSession.forbiddenErrorLoaded.emit();
    }


    private validateInput(): boolean {
        let toReturn: boolean = true;
        var errorMsgList = [];
        if (this.newERNumberDescription.length < 2) {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New ER', properties: { category: 'Error-An engineering request number requires a detailed description.', label: this._quoteSession.defaultUser, value: 1 } });

            errorMsgList.push({ severity: 'warn', summary: 'Warning', detail: "An engineering request number requires a detailed description." });
            toReturn = false;
        }

        this._messageService.addAll(errorMsgList);
        return toReturn;
    }
    //#endregion GET NEW ER


    //#region HELPER METHODS
    private resetVars(): void {
        this.displayCreateER = true;
        this.newERNumber = null;
        this.newERNumberDescription = "";
    }


    private addGrowlMsg(success: boolean, message: string): void {
        this._messageService.clear();
        if (success)
            this._messageService.add({ severity: 'success', summary: 'Success', detail: message });
        else
            this._messageService.add({ severity: 'error', summary: 'Failed', detail: message });
    }
    //#endregion HELPER METHODS
}
