import { Component, ViewChild, ElementRef, OnInit, ChangeDetectorRef, HostListener, Renderer2 } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from "@angular/router";
import { ConfigService } from "../../_config";
import { QuoteService } from './quote.service';
import { QuoteSession } from './quote.session';
import { BaseComponent } from "../../_utils";
import { Angulartics2 } from 'angulartics2';
import { MessageService } from 'primeng/api';

import { Subject } from 'rxjs';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';


@Component({
    selector: 'quote',
    templateUrl: './quote.component.html',
    providers: [
        QuoteService
    ],
    styleUrls: ['./quote.component.css']
})

export class QuoteComponent extends BaseComponent implements OnInit {

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        // confirm leaving
        if (this.hasUnsavedModifications()) {
            $event.returnValue = true
        }
    }

    @ViewChild('container', { static: true })
    public container: ElementRef;

    public isChrome: boolean = true;
    public displayAll: boolean = false;
    public isLoading: boolean = true;

    public userName: string = "";
    private readonly _destroying$ = new Subject<void>();

    loginDisplay = false;

    constructor(
        private _confirmationService: ConfirmationService,
        private cd: ChangeDetectorRef,
        private _quoteSession: QuoteSession,
        private _quoteService: QuoteService,
        public _configService: ConfigService,
        private router: Router,
        private messageService: MessageService,
        public _angulartics: Angulartics2,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        super();
    }


    // Initiates any global variables that can be communicated through quote.session.ts
    ngOnInit() {

       
        this.initMain()

        this.navToError();

        this._quoteSession.newMPCLoaded
            .subscribe( () => {
                this.getMPCs();
        })

        this._quoteSession.newERNumberLoaded
            .subscribe( () => {
                this.getERNumbers();
        })

       
    }

    public ngAfterViewInit() {
        console.log("user", this._quoteSession.defaultUser);
        if (this._quoteSession.defaultUser != "" && this._quoteSession.defaultUser != undefined) {
            console.log("user", this._quoteSession.users);
            this._angulartics.setUsername.next(this._quoteSession.defaultUser);
            this._angulartics.setUserProperties.next({ userName: this._quoteSession.defaultUser });
        }
    }



    //#region INIT
    private initMain(): void {
        this._configService.changed
            .subscribe( () => {
                this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
                if (this.isChrome){
                    this ._quoteService.getAuthorisation()
                        .subscribe(authorisation => {
                            if (authorisation["isAuthorised"]){
                                var accountInfo = this._quoteService.getAccountInfo();
                                console.log(accountInfo["isAuthorised"]);
                                this.getUsers();
                                this.getTypes();
                                this.getERNumbers();
                                this.getMPCs();
                                this._quoteSession.defaultUser = accountInfo["user"];
                                this.isLoading = false;
                                this._angulartics.eventTrack.next({ action: 'Get Authorization', properties: { category: 'Authorized', label: this._quoteSession.defaultUser, value: 0 } });
                                this.cd.detectChanges();
                                this._quoteSession.quoteLoaded.emit();
                            }                            
                        }, err => {
                            this._angulartics.eventTrack.next({ action: 'Get Authorization', properties: { category: err, label: this._quoteSession.defaultUser, value: 0} });
    
                            console.log(err);
                            if( err.toString().substring(0,3) == "403"){
                                this._quoteSession.forbiddenErrorLoaded.emit();
                            } else if( err.toString().substring(0, 3) == "401" || err.toString() === "Server error" ){
                                this._quoteSession.unauthorisedErrorLoaded.emit();
                            } else if( err.toString().substring(0, 3) == "500" ){
                                this._quoteSession.serverErrorLoaded.emit();
                            } else {
                                // Default condition when any other error comes up
                                this._quoteSession.unknownErrorLoaded.emit();
                            } 
                            this.isLoading = false;
                        })
                    }
                else {
                    this._angulartics.eventTrack.next({ action: 'Get Authorization', properties: { category: 'Number Picker application only works in Google Chrome.', label: this._quoteSession.defaultUser, value: 0 } });
                    console.log("Number Picker application only works in Google Chrome.");
                }
              
            });
        
    }


    private navToError(){

        this._quoteSession.forbiddenErrorLoaded
            .subscribe( () => {
                this.router.navigate(['/error', 403]);
        });
    
        this._quoteSession.unauthorisedErrorLoaded
            .subscribe( () => {
                this.router.navigate(['/error', 401]);
        });

        this._quoteSession.unknownErrorLoaded
            .subscribe( () => {
                this.router.navigate(['/error', 0]);
        });
    }


    public onHideIsChrome(){
        this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    }
    //#endregion INIT


    //#region GET
    private getTypes(): void {
        this._quoteService.getAllTypes()
            .toPromise()
            .then( res => { 
                if( res ){
                    this._quoteSession.types = res;
                    this._quoteSession.typesLoaded.emit();
                }
            },
            err => {
                console.log(err);
                this.addGrowlMsg(false, err);
            })
    }


    private getERNumbers(): void {
        this._quoteService.getAllERNumbers()
            .toPromise()
            .then( res => {
                if( res ){
                    this._quoteSession.erNumbers = res;
                    this._quoteSession.erNumbersLoaded.emit();
                }
            },
            err => {
              console.log(err);
              this.addGrowlMsg(false, err);
            });
    }


    private getMPCs(): void {
        this._quoteService.getAllMajorProductCodes()
            .toPromise()
            .then( res => {
                if( res ){
                    this._quoteSession.mpcs = res;
                    this._quoteSession.mpcsLoaded.emit();
                }
            },
            err => {
              console.log(err);
              this.addGrowlMsg(false, err);
            });
    }


    private getUsers(): void {
        this._quoteService.getAllUsers()
            .toPromise()
            .then( res => {
                if (res) {
                    this._quoteSession.users = res;
                    this._quoteSession.usersLoaded.emit();
                }
            },
            err => {
                console.log(err);
                this.addGrowlMsg(false, err);
            });
    }
    //#endregion GET


    //#region HELPER METHODS
    public hasUnsavedModifications(): boolean {
        return this._quoteSession.hasModifications;
    }


    public confirmEscapeSearchTabView() {
        this._angulartics.eventTrack.next({ action: 'Confirm Escape Search', properties: { category: '', label: this._quoteSession.defaultUser, value: 0 } });

        this._confirmationService.confirm({
            message: 'Are you sure that you want to quit Search tab? Any unsaved moficiations will be lost.',
            accept: () => {
                //Actual logic to perform a confirmation
                this._angulartics.eventTrack.next({ action: 'Confirm Escape Search', properties: { category: 'Accepted', label: this._quoteSession.defaultUser, value: 0 } });
            }
        });
    }


    private addGrowlMsg(success: boolean, message: string): void {
        this.messageService.clear();
        if( success )
            this.messageService.add({severity:'success', summary: message, detail:'Your search has been performed successfully'});
        else
            this.messageService.add({severity:'error', summary: 'Search Failed', detail: message });
    }
    //#endregion HELPER METHODS


    getControls() {
        let controls = [];
        return controls;
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
      }
}