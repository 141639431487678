<!-- New Engineering Request Description field -->
<div class="er-dialog">
    <p-dialog header="Create a New Engineering Request Number" [(visible)]="displayCreateER" [responsive]="true" showEffect="fade" [modal]="true" [style]="{'width':'600px'}">
        <div class="row" style="padding-top:15px;">
            <div class="col-xs-12">
                <label class="basic-label asterisk">Description</label>
            </div>
            <div class="col-xs-12">
                <textarea pInputText placeholder="Please enter a description for this engineering request number"
                cols="80" rows="6" maxlength="150" type="text" [(ngModel)]="newERNumberDescription" style="resize: none;"></textarea>
            </div>
        </div>
      
        <ng-template pTemplate="footer">
            <button pButton class="basic-button" icon="fa fa-check" (click)="getNewER()" label="Get New ER"></button>
        </ng-template>
      </p-dialog>
</div>



<!-- ER Number Addition Success Popup -->
<app-single-addition-success [singleNumber]="newERNumberStr"></app-single-addition-success>


