import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuoteService } from '../../quote.service';
import { QuoteSession } from '../../quote.session';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { _MajorProductCode } from '../../../../_models/_MajorProductCode';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'app-major-product-code-addition',
    templateUrl: './major-product-code-addition.component.html',
    styleUrls: ['./major-product-code-addition.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MajorProductCodeAdditionComponent implements OnInit {
    public mpc: string = "";
    private mpcs: any[] = [];
    private newMPC: any;

    public displayCreateMPC: boolean = false;

    constructor(
        private _quoteSession: QuoteSession,
        private _quoteService: QuoteService,
        private _messageService: MessageService,
        public _angulartics: Angulartics2
    ) { }

    ngOnInit() {
        this._quoteSession.quoteLoaded
            .subscribe(() => {
                this.displayCreateMPC = false;
            })

        this.initMPCs();
        this.onNewMPCRequested();
    }

    //#region INIT
    private initMPCs(): void {
        this._quoteSession.mpcsLoaded
            .subscribe(() => {
                this.mpcs = this._quoteSession.mpcs;
            });
    }


    private onNewMPCRequested(): void {
        this._quoteSession.newMPCRequested
            .subscribe(res => {
                this.mpc = res;
                this.displayCreateMPC = true;
            });
    }
    //#endregion INIT


    //#region CREATE NEW MPC
    private createMPC(): void {
        

        this.displayCreateMPC = false;
        let request: _MajorProductCode = new _MajorProductCode(0, this.mpc, "", "");
        this._quoteService.putMajorProductCode(request)
            .subscribe(
                newMPC => this.onCreateMPCSuccess(newMPC),
                error => this.onCreateMPCError(error)
            );
    }


    private onCreateMPCSuccess(newMPC: any) {
        var jsonMPC = JSON.parse(JSON.stringify(newMPC));
        if (jsonMPC.mpc != undefined) {
            this.newMPC = jsonMPC;
            this._quoteSession.mpcs.push({ mpc: this.newMPC["mpc"] });
            this._quoteSession.newMPCLoaded.emit(this.newMPC["mpc"]);
            this.addGrowlMsg(true, "A new MPC " + this.newMPC["mpc"] + " has been successfully created.");

            this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New MPC', properties: { category: ('A new MPC ' + this.newMPC["mpc"] + ' has been successfully created.'), label: this._quoteSession.defaultUser, value: 1 } });

        }
        else {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New MPC', properties: { category: ('Error-There was an error whilst creating a new major product code.'), label: this._quoteSession.defaultUser, value: 1 } });

            console.log("number-addition-component.ts: showCreateER() -> There was an error whilst getting a new ER number.");
            this.addGrowlMsg(false, "There was an error whilst creating a new major product code.");
        }
    }


    private onCreateMPCError(error: string) {
        this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New MPC', properties: { category: error, label: this._quoteSession.defaultUser, value: 1 } });

        console.log(error);
        this.addGrowlMsg(false, error);
        if (error.toString().substring(3) == "403")
            this._quoteSession.forbiddenErrorLoaded.emit();
    }


    public validateInput(): void {
        let isValidInput: boolean = true;
        var errorMsgList = [];

        for (var i = 0; i < this.mpcs.length; i++) {
            if (this.mpc != null &&
                this.mpc != undefined &&
                this.mpc.trim() != "" &&
                this.mpcs[i]["mpc"].toLowerCase() == this.mpc.toLowerCase()) {
                isValidInput = false;
                errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "MPC '" + this.mpc + "' already exists in the database. Please select the MPC from the dropdown." });
                this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New MPC', properties: { category: ('Error-MPC ' + this.mpc + ' already exists in the database. Please select the MPC from the dropdown.' ), label: this._quoteSession.defaultUser, value: 1 } });
                break;
            }
        }

        if (this.mpc == null || this.mpc == undefined || this.mpc == "") {
            isValidInput = false;
            errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "A new MPC cannot be blank." });
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Create New MPC', properties: { category: ('Error-A new MPC cannot be blank.'), label: this._quoteSession.defaultUser, value: 1 } });

        }

        this._messageService.addAll(errorMsgList);

        if (isValidInput)
            this.createMPC();
    }
    //#endregion CREATE NEW MPC


    //#region HELPER METHODS
    private addGrowlMsg(success: boolean, message: string): void {
        this._messageService.clear();
        if (success)
            this._messageService.add({ severity: 'success', summary: 'Success', detail: message });
        else
            this._messageService.add({ severity: 'error', summary: 'Failed', detail: message });
    }
    //#endregion HELPER METHODS
}
