import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { _ActiveNumber } from '../../_models/_ActiveNumber';

// Session Information Class (Singleton)
@Directive()
@Injectable()
export class QuoteSession {
    // Current user who is using the frontend. Authorised by the active directory system.
    public defaultUser: string;
    public defaultUserExists: boolean = false; // to notify that the page has been fully loade.d
    public cols: any [] = [
        { field: 'code', header: 'Type', styletext: 'flex: 0 0 9em; display: initial; background: #ebedf0' },
        { field: 'formattedNumber', header: 'Number', styletext: 'flex: 0 0 7em; background: #ebedf0'  },
        { field: 'formattedEngineeringRequestNumber', header: 'ER', styletext: 'flex: 0 0 7.5em; background: #ebedf0'  },
        { field: 'description', header: 'Description', styletext: ' background: #ebedf0'  },
        { field: 'majorProductCode', header: 'MPC', styletext: 'flex: 0 0 7em; background: #ebedf0'  },
        { field: 'createdBy', header: 'Created By', styletext: 'flex: 0 0 15em; background: #ebedf0'  },
        { field: 'createdUtc', header: 'Created', styletext: 'flex: 0 0 11em; background: #ebedf0'  }
      ];

    // Used to notify other components that the main quote.component.ts has been changed.
    @Output() quoteLoaded: EventEmitter<any> = new EventEmitter<any>(true); 
    @Output() typesLoaded: EventEmitter<any> = new EventEmitter<any>(true); 
    public types: any;
    @Output() erNumbersLoaded: EventEmitter<any> = new EventEmitter<any>(true); 
    public erNumbers: any;
    @Output() usersLoaded: EventEmitter<any> = new EventEmitter<any>(true); 
    public users: any;
    @Output() mpcsLoaded: EventEmitter<any> = new EventEmitter<any>(true); 
    public mpcs: any;

    // used to notify that the single addition button is clicked in table.component.ts.
    @Output() singleAdditionRequested: EventEmitter<any> = new EventEmitter<any>(true);
    public displaySingleAddPopup: boolean = false;

    // to request for an ER number
    @Output() newERNumberRequested: EventEmitter<any> = new EventEmitter<any>(true);
    @Output() newERNumberLoaded: EventEmitter<any> = new EventEmitter<any>(true);
    public selectedERNumber: any;
    
    // to request for a MPC
    @Output() newMPCRequested: EventEmitter<string> = new EventEmitter<string>();
    @Output() newMPCLoaded: EventEmitter<string> = new EventEmitter<string>();

    @Output() forbiddenErrorLoaded: EventEmitter<any> = new EventEmitter<any>(true);
    @Output() unauthorisedErrorLoaded: EventEmitter<any> = new EventEmitter<any>(true);
    @Output() serverErrorLoaded: EventEmitter<any> = new EventEmitter<any>(true);
    @Output() unknownErrorLoaded: EventEmitter<any> = new EventEmitter<any>(true);

    @Output() numberHistoryRequested: EventEmitter<_ActiveNumber> = new EventEmitter<_ActiveNumber>();

    // to confirm that user is sure to escape each tab
    @Output() searchTabEscapeRequested: EventEmitter<any> = new EventEmitter<any>(true);
    @Output() searchTabEscapeConfirmed: EventEmitter<any> = new EventEmitter<any>(true);
    public hasModifications: boolean = false;
    public escapeSearchTab: boolean;
    @Output() additionTabEscapeConfirm: EventEmitter<any> = new EventEmitter<any>(true);
    public filteringIndex: number;

    @Output() multipleNumberEditionRequested: EventEmitter<_ActiveNumber []> = new EventEmitter<_ActiveNumber []>();
    @Output() modifiedSuccessListLoaded: EventEmitter<_ActiveNumber []> = new EventEmitter<_ActiveNumber []>();
    @Output() addMoreRequested: EventEmitter<any> = new EventEmitter<any>(true);
    public numberEditionCols: any;
    public modifiedSuccessList: _ActiveNumber [];
    public multipleNumberEditionList: any;
    public hasSerialNumber: boolean;

    //filter-user.component.ts -> if user changes, we filter the search table filtered by the selected user.
    @Output() selectedUserChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() searchTableChanged: EventEmitter<string> = new EventEmitter<string>();

    //number-addition.component.ts -> invoked whenever ER number or MPC is changed.
    @Output() numberAdditionERNumberChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() numberAdditionMPCChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }
}