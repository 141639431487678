<p-autoComplete 
    styleClass="filter-er-component"
    appendTo="body"
    maxlength="8"
    [(ngModel)]="selectedERNumber"
    [suggestions]="filteredERNumbers"
    (onSelect)="onSelect($event);"
    placeholder="{{placeholder}}"
    (onFocus)="onFocus(rowData);"
    (onBlur)="onBlur(rowData);"
    (keydown.tab)="onBlur(rowData);"
    (keydown.shift.tab)="onBlur(rowData);"
    (onClear)="onClear(rowData)"
    (completeMethod)="onCompleteMethod($event)"
    [minLength]="3"
    (ngModelChange)="onNgModelChange(rowData);"
    [multiple]="false" 
    [forceSelection]="false">
</p-autoComplete>
