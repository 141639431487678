import { _ActiveNumber } from '../../../_models/_ActiveNumber';
import { Injectable } from "@angular/core";

@Injectable()
export class FilterService  {

  constructor(
    
  ) { }


  public addRowData(rowData: any, modifiedRowDataList: _ActiveNumber [], originalRowDataList: any, selectedERNumber: string, 
                    selectedMPC: string, displayModifiedRows: boolean) : boolean {
    let exists: boolean = false;
    let index: number = -1;
    rowData.isEdited = true;
    for( var i = 0; i < modifiedRowDataList.length; i++ ){
      if(modifiedRowDataList[i]["numberId"] == rowData["numberId"]){
        index = i;
        exists = true;
        break;
      }
    }
    // if there already exists the selectedER in the modifiedRowDataList, we just update it as the user types, otherwise we insert a new rowData 
    // into the modifiedRowDataList.
    if( exists ){
      modifiedRowDataList[index] = rowData;
      if( selectedERNumber != undefined && selectedERNumber != null ){
        modifiedRowDataList[index]["formattedEngineeringRequestNumber"] = selectedERNumber;
      }
      if( selectedMPC != undefined && selectedMPC != null ){
        modifiedRowDataList[index]["majorProductCode"] = selectedMPC;
      }
      // if the rowData matches the original data, then we simply unflag isEdited boolean value.
      for( var i = 0; i < originalRowDataList.length; i++ ) {
        if( originalRowDataList[i][0] == rowData["numberId"]){
          if( originalRowDataList[i][1] == rowData["description"] && originalRowDataList[i][2] == rowData["formattedEngineeringRequestNumber"] &&
            originalRowDataList[i][3] == rowData["majorProductCode"]){
            rowData.isEdited = false;
            if( !displayModifiedRows ){
              modifiedRowDataList.splice(index, 1);
            }
          }
        }
      }
    }
    else{ 
      if( selectedERNumber != undefined && selectedERNumber != null ){
        rowData["formattedEngineeringRequestNumber"] = selectedERNumber;
      }
      if( selectedMPC != undefined && selectedMPC != null ){
        rowData["majorProductCode"] = selectedMPC;
      }
      modifiedRowDataList.push(rowData);
    }

    // If there is a change in search table, users are prompted with confirm to leave the site message.
    let hasModifications: boolean = false;
    for( var i = 0; i < modifiedRowDataList.length; i++ ){
      if( modifiedRowDataList[i].isEdited ){
        hasModifications = true;
        break;
      }
    }
    return hasModifications;
  }


  public addOriginalRowData(rowData: _ActiveNumber, originalRowDataList: any []): any [] {
    let exists: boolean = false;
    for( var i = 0; i < originalRowDataList.length; i++ ){
      if( originalRowDataList[i][0] == rowData["numberId"] ){
        exists = true;
        break;
      }
    }
    if( !exists ){
        var toPush = [];
        toPush.push(rowData["numberId"]); 
        toPush.push(rowData["description"]); 
        toPush.push(rowData["formattedEngineeringRequestNumber"]);
        toPush.push(rowData["majorProductCode"]);  
        originalRowDataList.push(toPush);
    }
    return originalRowDataList;
  }


  public checkIsEdited(rowData: any, modifiedRowDataList: _ActiveNumber [], originalRowDataList: any, isValidMPC: boolean, displayModifiedRows: boolean ): void {
    let isEdited: boolean = true;
      for (var i = 0; i < originalRowDataList.length; i++){
          if( originalRowDataList[i][0] == rowData["numberId"] ){
            if( !isValidMPC )
                rowData["majorProductCode"] = originalRowDataList[i][3];

            if( originalRowDataList[i][1] == rowData["description"] && originalRowDataList[i][2] == rowData["formattedEngineeringRequestNumber"] 
                && originalRowDataList[i][3] == rowData["majorProductCode"] ){
              rowData["isEdited"] = false;
              isEdited = false;
              break;
            }
          }
    } 
    if( isEdited ){
      rowData["isEdited"] = true;
    } else {
      for( var i = 0; i < modifiedRowDataList.length; i++ ){
        if( modifiedRowDataList[i]["numberId"] == rowData["numberId"] && !displayModifiedRows ){
          modifiedRowDataList.splice(i, 1);
        }
      }
    }
  }


  public verifyMPCs( rowDataList: _ActiveNumber [], mpcs: any [] ): boolean {
      let isValidMPC: boolean = true;

    for( var i = 0; i < rowDataList.length; i++ ){
        let mpcExists: boolean = false;
        if (rowDataList[i]["code"].toString().trim() == "Part" || rowDataList[i]["code"].toString().trim() == "Assembly" || rowDataList[i]["code"].toString().trim() == "Special" || rowDataList[i]["code"].toString().trim() == "Experimental")
        {
            if (rowDataList[i]["majorProductCode"].toString().trim() == "") {
                isValidMPC = false;
                mpcExists = false;
                break;
            }

        }
      if( rowDataList[i]["majorProductCode"].toString().trim() == "" ){
        mpcExists = true;
        break;
      }
      else{
        for( var j = 0; j < mpcs.length; j++ ) {
	  // Case insensitive comparison
          if( rowDataList[i]["majorProductCode"].toUpperCase() == mpcs[j]["mpc"].toUpperCase() ){
            mpcExists = true;
            rowDataList[i]["majorProductCode"] = mpcs[j]["mpc"];
            break;
          }
        }
      }
      if( !mpcExists ) {
        isValidMPC = false;
        break;
      }
    }
    return isValidMPC;
  }

    public verifyDescription(rowDataList: _ActiveNumber[], mpcs: any[]): boolean {
        let isValidDescription: boolean = true;

        for (var i = 0; i < rowDataList.length; i++) {
            if (rowDataList[i]["code"].toString().trim() != "MACAddress" ) {
                if (rowDataList[i]["description"].toString().trim() == "") {
                    isValidDescription = false;
                    break;
                }

            }
        }
        return isValidDescription;
    }

    public verifyER(rowDataList: _ActiveNumber[], mpcs: any[]): boolean {
        let isValidER: boolean = true;

        for (var i = 0; i < rowDataList.length; i++) {
            console.log(rowDataList[i]["code"].toString().trim());
            if (rowDataList[i]["code"].toString().trim() != "MACAddress" && rowDataList[i]["code"].toString().trim() != "Antec Project" && rowDataList[i]["code"].toString().trim() != "Antec Special Sequence" && rowDataList[i]["code"].toString().trim() != "Engineering Request") {
                if (rowDataList[i]["formattedEngineeringRequestNumber"].toString().trim() == "") {
                    isValidER = false;
                    break;
                }

            }
        }
        return isValidER;
    }
}
