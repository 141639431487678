import { BaseComponent } from '../../../../_utils';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-single-addition-success',
  templateUrl: './single-addition-success.component.html',
  styleUrls: ['./single-addition-success.component.css']
})
export class SingleAdditionSuccessComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() singleNumber: string;
  public display: boolean = false;
  constructor(
    private _messageService: MessageService
  ) { 
    super();
  }

  ngOnInit() {
    this.display = false;
  }


  ngOnChanges() {
    this.display = true;   
  }


  public copySingleNumber(): void {
    this.copyToClipboard(this.singleNumber);
    this._messageService.add({severity:'success', summary: 'Success', detail: "Copied Successfully!" });
  }
}
