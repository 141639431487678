<div class="mpc-dialog">
  <p-dialog header="Create a New Major Product Code" [(visible)]="displayCreateMPC" [responsive]="true" showEffect="fade" [modal]="true" [style]="{'width':'600px'}">
    <div class="row" style="padding-top:15px;">
        <div class="col-xs-12">
            <label class="modal-label asterisk">Major Product Code</label>
        </div>
        <div class="col-xs-12">
          <input pInputText type="text" size="50" [(ngModel)]="mpc" maxlength="25"> 
        </div>
    </div>
    
    <ng-template pTemplate="footer">
        <button pButton class="basic-button" icon="fa fa-check" (click)="validateInput()" label="Create MPC"></button>
    </ng-template>
  </p-dialog>
</div>