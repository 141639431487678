import { Injectable } from '@angular/core';
import { ConfigService } from "../../_config";
import { BaseService } from "../../_utils";
import { HttpClient } from '@angular/common/http';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { MSAL_INSTANCE } from '@azure/msal-angular';
import { Inject } from '@angular/core';
import { throwError } from 'rxjs';


// Map the API to be a proxy class
@Injectable()
export class QuoteService extends BaseService {
    private indivDetails;
    private accountInfo: AccountInfo[];

    constructor(@Inject(MSAL_INSTANCE) private msalPublicClientApplication: IPublicClientApplication,public http: HttpClient, public cs: ConfigService) {
        super(http, cs);
    }

    // TYPE
    getAllUsers(){
        return this.get('/type/user');
    }


    getAllTypes(){
        return this.get('/type')
    }


    getAllERNumbers(){
        return this.get('/type/engineering-request-number')
    }


    getAllMajorProductCodes(){
        return this.get('/type/major-product-code')
    }

    
    // VERIFICATION
    getDomain(){
        return this.get('/verification/domain');
    }

    getAuthorisation(){
        return this.get('/verification/authorisation');
    }
    
    getAccountInfo(){
        this.accountInfo = this.msalPublicClientApplication.getAllAccounts();

        if(this.accountInfo.length != 1)
        {
            var error = 500 + "Number of signed-in accounts does not equal 1."
            return throwError(error)
        }

        return {"isAuthorised": true, "user": "CORP\\" + this.accountInfo[0]["username"].split('@')[0]}
    }


    // NUMBER
    getAllFormattedNumbers(user: string){
        if( user == "" )
            return this.get('/number')
        else
            return this.get('/number/' + user);
    }


    getNumberHistory(numberId: number){
        return this.get('/number/' + numberId + '/attribute');
    }


    putNewNumber(body: any) {
        return this.put('/number', body);
    }

    
    putMajorProductCode(body: any) {
        return this.put('/number/major-product-code', body);
    }

    
    putUpdatedNumber(body: any) {
        return this.put('/number/edition', body);
    }
}