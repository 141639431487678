<div class="container" #container>
    <layout-header></layout-header>

    <div class="mainContent" >

        <div *ngIf="isForbidden">
            <div class="row">
                <div class="col-xs-4">
                </div>
                <div class="col-xs-4">
                    <p class="error-header-label">{{errorStatus}}</p>
                </div>
                <div class="col-xs-4">
                </div>
            </div>
    
            <div class="row">
                <div class="col-xs-4">
                </div>
                <div class="col-xs-4">
                    <p class="basic-label">Your domain account is: {{domain}}</p>
                    <br>
                    <p class="basic-label">{{message}}</p>
                    <br>
                    <p class="basic-label">Please email <a href="mailto:{{email}}&subject=Request permission to access Numpick System&body=Please add my domain user account {{domain}} to the domain group WPG\Numpick so that I can access the Numpick System.">{{email}}</a> to have them add your domain account.</p>
                </div>
                <div class="col-xs-4">
                </div>
            </div>
        </div>


        <div *ngIf="isUnauthorised">
            <div class="row">
                <div class="col-xs-4">
                </div>
                <div class="col-xs-4">
                    <p class="error-header-label">{{errorStatus}}</p>
                </div>
                <div class="col-xs-4">
                </div>
            </div>

            <div class="row">
                <div class="col-xs-4">
                </div>
                <div class="col-xs-4">
                    <br>
                    <p class="basic-label">{{message}}</p>
                    <br>
                    <p class="basic-label">If you need further assistance with your sign-in credentials, please email <a href="mailto:{{email}}">{{email}}</a>.</p>
                </div>
                <div class="col-xs-4">
                </div>
            </div>
        </div>


        <div *ngIf="isUnknown">
            <div class="row">
                <div class="col-xs-4">
                </div>
                <div class="col-xs-4">
                    <p class="error-header-label">{{errorStatus}}</p>
                </div>
                <div class="col-xs-4">
                </div>
            </div>

            <div class="row">
                <div class="col-xs-4">
                </div>
                <div class="col-xs-4">
                    <br>
                    <p class="basic-label">{{message}}</p>
                    <br>
                    <p class="basic-label">Please try again and if the error reoccurs, please email <a href="mailto:{{email}}">{{email}}</a>.</p>
                </div>
                <div class="col-xs-4">
                </div>
            </div>
        </div>
    </div>
</div>
