<layout-environment></layout-environment>

<div class="row" id="SiteHeader">
  <div class="col-sm-9 logo">
    <a href="" target="_self">
      <img class="logo" height="35" width="169" src="assets/images/main/price-automation-logo.png" alt="Price Industries">
    </a>
  </div>
</div>

<div id="SiteSubHeader">
  <div class="row">
    <header>
      <div class="col-sm-12">
        <h1>Number Picker</h1>
      </div>
    </header>
  </div>
</div>
