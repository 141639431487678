import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../_utils';
import { QuoteSession } from '../../../quote/quote.session';
import { _ActiveNumber } from '../../../../_models/_ActiveNumber';
import { FilterService } from '../filter.service';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-filter-mpc',
  templateUrl: './filter-mpc.component.html',
  styleUrls: ['./filter-mpc.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FilterMPCComponent extends BaseComponent implements OnInit {
  // MPC filtering
  @Input() selectedMPC: any;
  @Input() modifiedRowDataList: _ActiveNumber [];
  @Input() originalRowDataList: any;
  @Input() selectedERNumber: string;
  @Input() displayModifiedRows: boolean;
  @Input() rowData: _ActiveNumber;
  @Input() placeholder: string;
  @Input() table: Table;

  private mpcs: any;
  public filteredMPCs: any [] = [];

  constructor(
    private _filterService: FilterService,
    private _quoteSession: QuoteSession,
    private _messageService: MessageService
  ) { 
    super();
  }

  ngOnInit() {
    this.initMPC();
    this.setNewMPC();
  }


  private initMPC(): void {
    this.mpcs = this._quoteSession.mpcs;
  }


  private setNewMPC(): void {
    this._quoteSession.newMPCLoaded
      .subscribe( selectedMPC => {
        this.mpcs = this._quoteSession.mpcs;
        this.filteredMPCs = [];
        this.selectedMPC = selectedMPC;
    });
  }


  public onCompleteMethod(event: any): void {
    this.initMPC();
    let filtered : any [] = [];
    var errorMsgList = [];
    if( event.query.trim() != "" ){
      for(let i = 0; i < this.mpcs.length; i++) {
          let majorProductCode: string = this.mpcs[i]["mpc"];
          if(majorProductCode.toLowerCase().indexOf(event.query.toLowerCase()) >= 0) {
              filtered.push(majorProductCode);
          }
      }
      if( filtered.length == 0){
        errorMsgList.push( {severity: 'error', summary: 'Failed', detail: "No matching MPC numbers were found."});
        if( this.rowData != undefined ){
          this._filterService.checkIsEdited(this.rowData, this.modifiedRowDataList, this.originalRowDataList, false, this.displayModifiedRows);
          this.selectedMPC = this.rowData["majorProductCode"];
        }
      }
      else if( filtered.length == 1 ){
        this.selectedMPC = filtered[0];
        if( this.rowData != undefined )
          this._quoteSession.hasModifications = this._filterService.addRowData(this.rowData, this.modifiedRowDataList, this.originalRowDataList, this.selectedERNumber, this.selectedMPC, this.displayModifiedRows);
        else
          this._quoteSession.numberAdditionMPCChanged.emit(this.selectedMPC);
      }
    }
    else {
      this.selectedMPC = null;
    } 
    this._messageService.addAll(errorMsgList);
    this.filteredMPCs = filtered;
  }
    

  public onFocus(rowData: any): void {
    if( rowData != undefined ){
      this.selectedERNumber = null;
      this.selectedMPC = rowData["majorProductCode"];
    }
  }


  public onBlur(rowData: any): void {
    if( rowData != undefined ){
      this.selectedMPC = null;
      let isValidMPC: boolean = false;
      if( rowData["majorProductCode"].trim() == "" ){
        isValidMPC = true;
      } 
      else {
        for( var i = 0; i < this.mpcs.length; i++ ){
          if( rowData["majorProductCode"] == this.mpcs[i]["mpc"] ){
            isValidMPC = true;
            break;
          }
        }
      }
      this._filterService.checkIsEdited(rowData, this.modifiedRowDataList, this.originalRowDataList, isValidMPC, this.displayModifiedRows);
    }
    if(this.table != null){
      //this.table.editingCell = null;
    }
  }


  public onSelect(selectedMPC: string): void {
    if( this.rowData == undefined ){
      this.selectedMPC = selectedMPC;
      this._quoteSession.numberAdditionMPCChanged.emit(this.selectedMPC);
    }
  }

  
  public onNgModelChange(rowData: _ActiveNumber): void {
    if( rowData != undefined )
      this._quoteSession.hasModifications = this._filterService.addRowData(rowData, this.modifiedRowDataList, this.originalRowDataList, this.selectedERNumber, this.selectedMPC, this.displayModifiedRows);
    else {
      this._quoteSession.numberAdditionMPCChanged.emit(this.selectedMPC);
    }
  }
}
