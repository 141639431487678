<!-- Modification Success Popup -->
<p-dialog header="Your Modification(s)" [(visible)]="display" [responsive]="true" showEffect="fade" [modal]="true" [style]="{'width':'800px'}" >
    <p-table #dt [columns]="cols" [value]="modifiedSuccessList" [paginator]="true" [rows]="10" sortMode="multiple" responsive="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <div *ngSwitchDefault>
                        {{rowData[col.field]}}
                    </div>
                    <div *ngSwitchCase="'createdUtc'">
                         {{convertToLocalTime(rowData[col.field])}}
                    </div>
         
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
