import { _ActiveNumber } from '../../../../_models/_ActiveNumber';
import { BaseComponent } from '../../../../_utils';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { QuoteSession } from '../../../quote/quote.session';

@Component({
  selector: 'app-modification-success',
  templateUrl: './modification-success.component.html',
  styleUrls: ['./modification-success.component.css']
})
export class ModificationSuccessComponent extends BaseComponent implements OnChanges, OnInit {
  @Input() modifiedSuccessList: _ActiveNumber [];
  public display: boolean = false;
  public cols: any [];

  constructor(
    private _quoteSession: QuoteSession
  ) { 
    super();
  }


  ngOnInit() {
    this.display = false;
    this.cols = this._quoteSession.cols;
  }


  ngOnChanges() {
    this.display = true;
  }


  public convertToLocalTime(createdUtc: string): string {
    return this.convertToLocalTimeString(createdUtc, false, "second");
  }
  
}
