import { Component, OnInit } from '@angular/core';
import { _ActiveNumber } from '../../../_models/_ActiveNumber';
import { BaseComponent } from "../../../_utils";
import { _NewNumberRequest } from '../../../_models/_NewNumberRequest';
import { _NumberType } from '../../../_models/_NumberType';
import { MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { QuoteService } from '../quote.service';
import { QuoteSession } from '../quote.session';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'app-number-addition',
    templateUrl: './number-addition.component.html',
    styleUrls: ['./number-addition.component.css']
})
export class NumberAdditionComponent extends BaseComponent implements OnInit {
    public selectedQuantity: any;
    public serialNumber: string = "";
    public selectedType: any;
    public selectedERNumber: any;
    public selectedMPC: any;
    public description: string = "";

    public isLoading: boolean = false;
    public displayGenNums: boolean = true;

    public asterisk: boolean = false;
    public asteriskMAC: boolean = false;

    public modifiedSuccessList: _ActiveNumber[];
    public displayModifiedSuccessPopup: boolean = false;
    public singleAddResult: string = "";

    public quantityOptions: any[] = [];
    public typeOptions: any[] = [];

    public cols: any = [
        { field: 'formattedNumber', header: 'Number' },
        { field: 'formattedEngineeringRequestNumber', header: ' ER ' },
        { field: 'majorProductCode', header: 'MPC' },
        { field: 'description', header: 'Description' },
    ];

    numberTypesWithOptionalECN: string[] = ['MACAddress', 'Antec Project', 'Antec Special Sequence', 'PRCN Project'];


    constructor(
        private _quoteSession: QuoteSession,
        private _quoteService: QuoteService,
        private _messageService: MessageService,
        public _angulartics: Angulartics2
    ) {
        super();
    }


    ngOnInit() {
        this.initTypes();
        this.setNewERNumber();
        this.setNewMPC();
        this.onModifiedSuccessListLoaded();
        this.onAddMoreRequested();

        this.onERNumberChanged();
        this.onMPCChanged();
    }


    //#region INIT
    private initNumberQuantity(quantity: number): void {
        this.quantityOptions = [];
        for (var i = 1; i <= quantity; i++)
            this.quantityOptions.push({ label: i.toString(), value: i });
        this.selectedQuantity = this.quantityOptions[0].value;
    }


    private initTypes(): void {
        this._quoteSession.typesLoaded
            .subscribe(
                () => {
                    this.initNumberQuantity(20);
                    this.typeOptions = [];
                    let tempTypeList: _NumberType[] = this._quoteSession.types;
                    for (var i = 0; i < tempTypeList.length; i++) {
                        if (tempTypeList[i]["code"] !== "Price Electronics UID" )
                            this.typeOptions.push({ label: { code: tempTypeList[i]["code"], formattedType: tempTypeList[i]["formattedType"] }, value: tempTypeList[i]["code"] });
                    }
                    if (this.typeOptions.length < 1)
                        console.log("number-addition.component.ts: initTypeList() -> There was an error whilst loading the list of Type.");;
                    this.selectedType = this.typeOptions[0].value;
                },
                error => {
                    console.log(error);
                    this.addGrowlMsg(false, error);
                });
    }


    private setNewERNumber(): void {
        this._quoteSession.newERNumberLoaded
            .subscribe(() => {
                this.selectedERNumber = this._quoteSession.selectedERNumber;
                this._quoteSession.quoteLoaded.emit();
            },
                err => {
                    console.log(err);
                    this.addGrowlMsg(false, err);
                });
    }


    private setNewMPC(): void {
        this._quoteSession.newMPCLoaded
            .subscribe(res => {
                this.selectedMPC = res;
            },
                err => {
                    console.log(err);
                    this.addGrowlMsg(false, err);
                });
    }
    //#endregion INIT


    //#region OnEventEmitter Response (onRequested, onLoad, and ETC)
    private onERNumberChanged(): void {
        this._quoteSession.numberAdditionERNumberChanged
            .subscribe(selectedERNumber => {
                this.selectedERNumber = selectedERNumber;
            });
    }


    private onMPCChanged(): void {
        this._quoteSession.numberAdditionMPCChanged
            .subscribe(selectedMPC => {
                this.selectedMPC = selectedMPC;
            });
    }


    private onModifiedSuccessListLoaded(): void {
        this._quoteSession.modifiedSuccessListLoaded
            .subscribe(modifiedSuccessList => {
                this.displayModifiedSuccessPopup = true;
                this.modifiedSuccessList = modifiedSuccessList;
                this.addMoreNumbers();
            });
    }


    private onAddMoreRequested(): void {
        this._quoteSession.addMoreRequested
            .subscribe(() => {
                this.addMoreNumbers();
            })
    }
    //#endregion OnEventEmitter Response (onRequested, onLoad, and ETC)


    //#region GETNUMBER
    public getNewNumbers(): void {
        this.displayGenNums = false;

        if (this.verifyRequestedFields()) {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Get Number Button', properties: { category: 'Fields Validated', label: this._quoteSession.defaultUser, value: 1 } });

            this.isLoading = true;
            let erNumber: string = (this.selectedERNumber == undefined || this.selectedERNumber == null) ? "" : this.convertToNumericERNumber(this.selectedERNumber);
            this.serialNumber = (this.serialNumber == undefined || this.serialNumber == null) ? "" : this.serialNumber;
            let majorProductCode: string = (this.selectedMPC == undefined || this.selectedMPC == null) ? "" : this.selectedMPC;
            this.configureColumns(this.selectedType);
            let request: _NewNumberRequest = new _NewNumberRequest(this.selectedType.toString(), Number(this.selectedQuantity), erNumber, majorProductCode, this.description, this.serialNumber.toString());
            this._quoteService.putNewNumber(request)
                .subscribe(
                    multipleNumberEditionList => this.onPutNewNumbersSuccess(multipleNumberEditionList),
                    error => this.onPutNewNumbersError(error)
                );
        }
        else {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Get Number Button', properties: { category: 'Error-There was an error(s) whilst generating new numbers for the user.', label: this._quoteSession.defaultUser, value: 1 } });

            this.displayGenNums = true;
            console.log("number-addition-component.ts: getNewNumbers() -> There was an error(s) whilst generating new numbers for the user.");
        }
    }


    private onPutNewNumbersSuccess(multipleNumberEditionList: any): void {
        {
            if (!this._quoteSession.defaultUserExists)
                this.addCurrentUser(this._quoteSession.defaultUser);
                        
            this._angulartics.setUserProperties.next({ userName: this._quoteSession.defaultUser });

            let editionList: _ActiveNumber[] = this.deserialiseFormattedNumber(multipleNumberEditionList);
            if (editionList.length > 1) {
                this._quoteSession.multipleNumberEditionRequested.emit(editionList);
                this.addGrowlMsg(true, "Multiple records were added successfully.");
                this._angulartics.eventTrack.next({ action: 'Generate Tab-Get Number Button', properties: { category: 'Multiple records were added successfully.', label: this._quoteSession.defaultUser, value: 1 } });
            }
            else if (editionList.length == 1) {
                this._angulartics.eventTrack.next({ action: 'Generate Tab-Get Number Button', properties: { category: 'A single record was added successfully.', label: this._quoteSession.defaultUser, value: 1 } });

                this.singleAddResult = editionList[0].formattedNumber;
                this._quoteSession.quoteLoaded.emit();
                this.addGrowlMsg(true, "A single record was added successfully.");
                this.addMoreNumbers();
            }
            else {
                this._angulartics.eventTrack.next({ action: 'Generate Tab-Get Number Button', properties: { category: 'Error-There was an error processing your request on the server side.', label: this._quoteSession.defaultUser, value: 1 } });
                this.addGrowlMsg(false, "There was an error processing your request on the server side.");
            }
            this.isLoading = false;
        }
    }


    private onPutNewNumbersError(error: string): void {
        this._angulartics.eventTrack.next({ action: 'Generate Tab-Get Number Button', properties: { category: 'Error-'+error, label: this._quoteSession.defaultUser, value: 1 } });
        console.log(error);
        this.addGrowlMsg(false, error);
        if (error.toString().substring(0, 3) == "403") {
            this._quoteSession.forbiddenErrorLoaded.emit();
        }
    }


    private addCurrentUser(user: string) {
        let exists: boolean = false;
        for (var i = 0; i < this._quoteSession.users.length; i++) {
            if (user.toLowerCase().indexOf(this._quoteSession.users[i].toLowerCase()) == 0) {
                exists = true;
            }
        }

        if (!exists) {
            this._quoteSession.users.push(user);
            this._quoteSession.defaultUserExists = true;
        }
    }


    private configureColumns(type: string): void {
        if (type == "MACAddress") {
            this._quoteSession.hasSerialNumber = true;
            if (this.cols.length == 4) {
                this.cols.push({ field: 'serialNumber', header: 'Serial Number' });
            }
        } else {
            this._quoteSession.hasSerialNumber = false;
            if (this.cols.length == 5)
                this.cols.pop();
        }
        this._quoteSession.numberEditionCols = this.cols;
    }


    private verifyRequestedFields(): boolean {
        let isValidRequest: boolean = true;
        let isMACAddress: boolean = false;
        let isAntec: boolean = false;
        var errorMsgList = [];

        let isValidTypeCode: boolean = false;
        this.typeOptions.forEach(type => {
            if (type.value === this.selectedType)
                isValidTypeCode = true;
        });
        if (!isValidTypeCode) {
            isValidRequest = false;
            errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Please specify the type from the dropdown." });
        } else if (this.selectedType === "MACAddress") {
            isMACAddress = true;
        }
        else if (this.selectedType === "Part" || this.selectedType === "Assembly" || this.selectedType === "Special" || this.selectedType === "Experimental") {
            if (this.selectedMPC == "" || this.selectedMPC == undefined) {
                isValidRequest = false;
                errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Please select a valid MPC from the dropdown or create a new MPC." });
            }
        }

        if (isMACAddress) {
            if (this.serialNumber == "" || this.serialNumber == undefined) {
                isValidRequest = false;
                errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Please specify a serial number for MAC Address." });
            }
        }
        else {
            if (this.description == "" || this.description == undefined) {
                isValidRequest = false;
                errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Please specify a description." });
            }
        }

        if (this.selectedERNumber == undefined || this.selectedERNumber == "") {
            if (!this.numberTypesWithOptionalECN.includes(this.selectedType)) {
                isValidRequest = false;
                errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Please specify one of the ER numbers or create a new ER number." });
            }
        }
        else {
            if (!this.isValidER(this.selectedERNumber)) {
                isValidRequest = false;
                errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Please specify one of the ER numbers or create a new ER number." });
            }
        }

        if (Number(this.selectedQuantity) <= 0 || Number(this.selectedQuantity) == undefined || Number(this.selectedQuantity > 20)) {
            isValidRequest = false;
            errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Quantity of Numbers must be between 1 and 20." });
        }

        if (!this.isValidMPC(this.selectedMPC)) {
            isValidRequest = false;
            errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "Please select a valid MPC from the dropdown or create a new MPC" });
        }


        this._messageService.addAll(errorMsgList);
        return isValidRequest;
    }

    private isValidMPC(majorProductCode: string) {
        let isValidMPC: boolean = false;
        if (majorProductCode != null && majorProductCode != undefined) {
            let majorProductCodes: any = this._quoteSession.mpcs;
            if (majorProductCode.trim() != "") {
                for (var i = 0; i < majorProductCodes.length; i++) {
                    // Case insensitive comparison
                    if (majorProductCode.toUpperCase() == majorProductCodes[i]["mpc"].toUpperCase()) {
                        isValidMPC = true;
                        this.selectedMPC = majorProductCodes[i]["mpc"];
                        break;
                    }
                }
            }
            else {
                isValidMPC = true;
                this.selectedMPC = "";
            }
        }
        else {
            isValidMPC = true;
            this.selectedMPC = "";
        }
        return isValidMPC;
    }

    private isValidER(eRNumber: string) {
        let isValidER: boolean = false;
        if (eRNumber != null && eRNumber != undefined) {
            let eRNumbers: any = this._quoteSession.erNumbers;
            if (eRNumber.trim() != "") {
                for (var i = 0; i < eRNumbers.length; i++) {
                    if (eRNumber.toUpperCase() == eRNumbers[i]["formattedNumber"]) {
                        isValidER = true;
                        break;
                    }
                }
            }
            else {
                isValidER = true;
                this.selectedERNumber = "";
            }
        }
        else {
            isValidER = true;
            this.selectedERNumber = "";
        }
        return isValidER;
    }

    //#endregion GETNUMBER


    //#region ONCLICK
    public onClickCreateER(): void {
        this._angulartics.eventTrack.next({ action: 'Generate Tab-Create ER Button', properties: { category: '', label: this._quoteSession.defaultUser, value:1 } });
        this._quoteSession.newERNumberRequested.emit();
    }


    public onClickCreateMPC(): void {
        let isExistingMPC: boolean = false;
        var errorMsgList = [];

        var majorProductCodes = this._quoteSession.mpcs;
        for (var i = 0; i < majorProductCodes.length; i++) {
            if (this.selectedMPC != null &&
                this.selectedMPC != undefined &&
                this.selectedMPC.trim() != "" &&
                majorProductCodes[i]["mpc"].toLowerCase() == this.selectedMPC.toLowerCase()) {
                isExistingMPC = true;
                break;
            }
        }       

        if (isExistingMPC) {
            errorMsgList.push({ severity: 'error', summary: 'Failed', detail: "MPC '" + this.selectedMPC + "' already exists in the database." });

            this._angulartics.eventTrack.next({ action: 'Generate Tab-Create MPC Button', properties: { category: 'MPC ' + this.selectedMPC + ' already exists in the database.', label: this._quoteSession.defaultUser, value: 1 } });

            this._messageService.addAll(errorMsgList);
        }
        else {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Create MPC Button', properties: { category: this.selectedMPC, label: this._quoteSession.defaultUser, value: 1 } });

            this._quoteSession.newMPCRequested.emit(this.selectedMPC);
        }
    }
    //#endregion ONCLICK


    //#region HELPER METHODS
    private addGrowlMsg(success: boolean, message: string): void {
        this._messageService.clear();
        if (success)
            this._messageService.add({ severity: 'success', summary: 'Success', detail: message });
        else
            this._messageService.add({ severity: 'error', summary: 'Failed', detail: message });
    }


    public addMoreNumbers(): void {
        this.selectedType = this.typeOptions[0].value; this.description = "";
        this.displayGenNums = true; this.selectedMPC = null; this.selectedQuantity = this.quantityOptions[0].value;
        this.selectedERNumber = null; this.serialNumber = "";
    }
    //#endregion Miscellaneous functions

}
