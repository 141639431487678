<p-autoComplete 
    styleClass="filter-mpc-component"
    appendTo="body"
    maxlength="50"
    [(ngModel)]="selectedMPC"
    [suggestions]="filteredMPCs"
    (completeMethod)="onCompleteMethod($event);"
    (onBlur)="onBlur(rowData);"
    (keydown.tab)="onBlur(rowData);"
    (keydown.shift.tab)="onBlur(rowData);"
    (onFocus)="onFocus(rowData);" 
    [minLength]="1"
    (ngModelChange)="onNgModelChange(rowData);"
    [multiple]="false"
    (onSelect)="onSelect($event);"
    placeholder="{{placeholder}}">
</p-autoComplete>
