export class Environment {

    public isDev: boolean;
    public isQA: boolean;
    public isProd: boolean;

    constructor(
        public env?: string
    ) {
        if (env == null)
            return;

        this.isDev = this.env.toUpperCase() == "DEV";
        this.isQA = this.env.toUpperCase() == "QA";
        this.isProd = this.env.toUpperCase() == "PROD";
    }

}

