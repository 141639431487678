<div class="row col-sm-12 validation-container">
    <hr class="validation-separator" *ngIf="!allValid()" />
    <div class="pull-right validation-container">
        <div *ngFor="let item of controls">
            <div class="row" *ngIf="!item.control.valid">
                <div class="col-sm-12 p-panel-title validation-summary"><a (click)="scrollToTag(item.anchor)">{{item.label}}</a></div>
                <br />
            </div>
        </div>
    </div>
</div>
