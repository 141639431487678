import { Component, OnInit, AfterViewInit, Input, ChangeDetectionStrategy, ChangeDetectorRef  } from '@angular/core';
import { BaseComponent } from "../../../_utils";

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: [
      './header.component.css'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})  
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewInit  {

    constructor(private cd: ChangeDetectorRef) {
        super();
    }
   
    ngOnInit() {
    }
 
    ngAfterViewInit() {
        this.cd.detectChanges();
    }



}
