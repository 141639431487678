import { Injectable } from "@angular/core";
 //custom validators from https://www.npmjs.com/package/ng2-validation, need to add more messages

@Injectable()
export class ValidationService {
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        let config = {
            'required': 'Required',
            'invalidEmailAddress': 'Invalid email address',
            'minlength': `Minimum length ${validatorValue.requiredLength}`,
            'maxlength': `Maximum length ${validatorValue.requiredLength}`,
            'equal': `Doesn't equal`,
            'min': `Minumum value ${validatorValue.min}`,
            'max': `Maximum value ${validatorValue.max}`,
            'minDate': `Date cannot be in the past`
        };

        return config[validatorName] || "field is invalid";
    }

    static emailValidator(control) {

        var lower_case_value = "";

        if (control.value) {
            lower_case_value = control.value.toLowerCase()
        }

        if (lower_case_value.match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,63}|[0-9]{1,3})(\]?)$/)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }
    }

}
