import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {  MsalBroadcastService, MSAL_INSTANCE } from '@azure/msal-angular';
import {  InteractionStatus, IPublicClientApplication, AccountInfo  } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import "reflect-metadata";
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
title = 'Microsoft identity platform';
isIframe = false;
loginDisplay = false;
private readonly _destroying$ = new Subject<void>();
public isLoading: Boolean;
public accountInfo: AccountInfo;

constructor(
  @Inject(MSAL_INSTANCE) private msalPublicClientApplication: IPublicClientApplication,
  private msalBroadcastService: MsalBroadcastService,
  angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
  //constructor() {
    angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    
    this.isIframe = window !== window.parent && !window.opener;
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.accountInfo = this.msalPublicClientApplication.getActiveAccount();
      });
  }


  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
