
import  config  from '../assets/data/qa/config.json';
export const environment = {
    production: true,
    configFile: 'assets/data/config.json',
    env: "QA",
    endpointUrl: config.endpointUrl,
    clientId: "c4990cdd-1227-41fe-a3a3-c05bbc90fe33",
    redirectUri: "/",
    apiScopes: ["api://82fb7ab9-c276-45bc-979d-92425bbcba56/access_as_user"]
};
