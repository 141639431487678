<p-toast life="4000"></p-toast>

<!-- Loading Spinner -->
<div *ngIf="isLoading">
    <div align="center" style="margin-top: 10em;">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>

<!-- Main Form -->
<div *ngIf="displayGenNums" style="margin-left: 30%">
    <div class="row" style="padding-top:15px;">
        <div class="col-xs-12">
            <label class="asterisk basic-label">Quantity</label>
            <label class="asterisk basic-label" style="margin-left: 5em;">Type</label>
            <span class="red-asterisk-text">(*) required fields</span>
            <br>
            Generate <p-dropdown [options]="quantityOptions" [(ngModel)]="selectedQuantity" [style]="{'width':'5em'}"></p-dropdown>
            <p-dropdown [options]="typeOptions" [(ngModel)]="selectedType" [style]="{'margin-left':'1.5em', 'width':'27em', 'vertical-align': 'top'}">
                <ng-template let-type pTemplate="selectedItem">
                    <div class="row">
                        <div class="col-xs-6">{{type.label.formattedType}}</div>
                        <div class="col-xs-6">{{type.label.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-types pTemplate="item">
                    <div class="row">
                        <div class="col-xs-6">{{types.label.formattedType}}</div>
                        <div class="col-xs-6">{{types.label.code}}</div>
                    </div>
                </ng-template>            
            </p-dropdown> {{selectedQuantity == 1 ? ' number' : ' numbers'}}
        </div>
    </div>
    
    <div *ngIf="selectedType=='MACAddress'">
        <div class="row" style="padding-top:15px;">
            <div class="col-xs-12">
                <label class="basic-label asterisk">Serial Number (Mac Address Only)</label>
            </div>
            <div class="col-xs-12">
                <input pInputText type="text" size="50" maxlength="50" [(ngModel)]="serialNumber"> 
            </div>
        </div>
    </div>  

    <div class="row" style="padding-top:15px;">
        <div class="col-xs-12">
            <div>
                <label [ngClass]="{'asterisk': !numberTypesWithOptionalECN.includes(selectedType)}" class="basic-label">Engineering Request Number (ER)</label>                
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="grid">
                    <div class="col-12 md:col-5">
                        <app-filter-er [placeholder]="'Enter ER or Create a new ER'"
                            [selectedMPC]="selectedMPC" [selectedERNumber]="selectedERNumber" [displayModifiedRows]="false">
                        </app-filter-er>
                    </div>
                    <div class="col-12 md:col-3">
                        <button pButton class="basic-button" icon="fa fa-plus" (click)="onClickCreateER()" label="Create ER"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="padding-top:15px;">
        <div class="col-xs-12">
            <label [ngClass]="{'basic-label': true, 'asterisk' : this.selectedType == 'Part' || this.selectedType == 'Assembly' || this.selectedType =='Special' || this.selectedType == 'Experimental' }">Major Product Code (MPC)</label>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="grid">
                    <div class="col-12 md:col-5">
                        <app-filter-mpc [placeholder]="'Enter MPC or Create a new MPC'"
                            [selectedMPC]="selectedMPC" [selectedERNumber]="selectedERNumber" [displayModifiedRows]="false">
                        </app-filter-mpc>
                    </div>
                    <div class="col-12 md:col-5">
                        <button pButton class="basic-button" icon="fa fa-plus" (click)="onClickCreateMPC()" label="Create MPC"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="padding-top:15px;">
        <div class="col-xs-12">
            <label [ngClass]="{'basic-label': true, 'asterisk' : this.selectedType != 'MACAddress' }">Description</label>
        </div>

        <div class="col-xs-12">
            <textarea pInputText cols="50" rows="5" maxlength="150" type="text" [(ngModel)]="description" style="resize: none;"></textarea>
        </div>
    </div>

    <div class="row" style="margin-top:2em; margin-bottom:2em;">
        <div class="col-xs-12 col-xs-offset-1" style="margin-top: 1em;">
            <button pButton class="basic-button" icon="fa fa-check" (click)="getNewNumbers()" label="Get {{selectedQuantity == 1 ? ' Number' : ' Numbers'}}"> </button>
        </div>
    </div>
</div>

<!-- Create a New ER Popup -->
<div>
    <app-engineering-request-addition></app-engineering-request-addition>
</div>

<!-- Create a New MPC Popup-->
<div>
    <app-major-product-code-addition></app-major-product-code-addition>
</div>

<!-- Multiple-Number-Edition table -->
<div>
    <app-multiple-number-edition></app-multiple-number-edition>
</div>



<app-single-addition-success [singleNumber]="singleAddResult"></app-single-addition-success>


<!-- Multiple Addition Success Popup -->
<app-modification-success [modifiedSuccessList]="modifiedSuccessList"></app-modification-success>

