<div *ngIf="displayNumberHistory" class="number-history-main">
    <p-dialog header="History for {{rowDataHistory.formattedNumber}}" [(visible)]="displayNumberHistory" >
    <div class="number-history">
        <p-table #dt [columns]="cols" [value]="numberHistory" [paginator]="true" [rows]="10" scrollHeight="flex" responsive="true" rowHover="true" class="history-table">
        <ng-template pTemplate="header" style="font-size: 1.6em; font-weight:bold;">Number History</ng-template>
    
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="number-history-centered">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
    
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <div *ngSwitchDefault>
                        {{rowData[col.field]}}
                    </div>
                    <div *ngSwitchCase="'createdUtc'">
                        {{convertToLocalTime(rowData[col.field])}}
                    </div>
                </td>
            </tr>
        </ng-template>
        </p-table>
    </div>
    </p-dialog>
</div>
