<p-autoComplete 
  [style]="{'margin-left':'1.5em', 'width':'100%'}"
  [suggestions]="filteredUsers"
  [(ngModel)]="selectedUser"
  [dropdown]="true"
  (completeMethod)="onCompleteMethod($event);"
  [autoHighlight]="true"
  (onSelect)="onSelect($event)"
  [multiple]="false"
  placeholder="Please select a user">
</p-autoComplete>
