export class Configuration {

    constructor(
        public env?: string,
        public baseApiUrl?: string,
        public openIdBaseApiUrl?: string,
        public openIdRedirectUrl?: string,
        public wellKnownConfigurationUrl?: string
    ) {
    }

}

