import { BaseComponent } from "../../_utils";
import { Component, OnInit, Input  } from '@angular/core';
import { QuoteService } from '../quote/quote.service';
import { QuoteSession } from '../quote/quote.session';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: [
      './error.component.css'
  ]
})
export class ErrorComponent extends BaseComponent implements OnInit {
    public completed: boolean = false;

    // 403 Forbidden Error properties.
    public isForbidden: boolean = false;
    public domain: string;
    public message: string;
    public email: string;
    public errorStatus: string;

    // 401 Unauthorised Error properties
    public isUnauthorised: boolean = false;

    public isUnknown: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _quoteService: QuoteService,
        private _quoteSession: QuoteSession
    ) {
        super();
    }

    ngOnInit(){
        this._route.params.subscribe( params => {
            var statusCode = params['statusCode'];
            this.errorHandler( statusCode );
        });
    }

    private errorHandler(statusCode: number): void {
        if( statusCode == 401 )
            this.onUnauthorisedError();
        else if( statusCode == 403 )
            this.onForbiddenError();
        else
            this.onUnknownError();
    }

    private onUnknownError(): void {
        this.isUnknown = true;
        this.errorStatus = "Unknown Error.";
        this.message = "An unexpected error with the Numpick server has occured.";
        this.email = "support@priceindustries.com";
    }

    private onForbiddenError(): void {
        this._quoteService.getDomain()
            .subscribe( res => {
                this.isForbidden = true;
                this.errorStatus = res[3] + " - Forbidden: Access is denied.";
                this.domain = res[0];
                this.message = res[1];
                this.email = res [2];
        });
    }

    private onUnauthorisedError(): void {
        this.isUnauthorised = true;
        this.errorStatus = "401 - Unauthorized: Access is denied due to invalid credentials.";
        this.message = "You do not have permission to access this application using the credentials that you supplied.";
        this.email = "support@priceindustries.com";
    }

    getControls() {
        let controls = [];
        return controls;
    }
}
