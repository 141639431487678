<div class="container" #container>
    <layout-header></layout-header>

    <div class="mainContent">

        <div *ngIf="isLoading && isChrome">
            <div align="center" style="margin-top: 10em;">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </div>

        <div *ngIf="!isLoading && isChrome" >
            <p-tabView #searchtabview [activeIndex]="activeIndeX" id="nav-top">
                <p-tabPanel header="  Generate" leftIcon="fa fa-plus" >
                    <app-number-addition></app-number-addition>
                </p-tabPanel>

                <p-tabPanel header="  Search" leftIcon="fa fa-search" id="search-panel">
                    <app-search-table></app-search-table>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
    <layout-footer></layout-footer>
</div>


<!-- Confirmation Dialog -->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" width="500" #cd>
    <ng-template pTemplate="footer">
        <div class="row">
            <div class="col-xs-6">
                <button [style]="{'text-align':'left'}" type="button" class="basic-button-long" pButton icon="fa fa-times" label="Discard Changes" (click)="cd.accept()" autofocus></button>
            </div>
            <div class="col-xs-6">
                <button type="button" class="basic-button" pButton icon="fa fa-check" label="Keep Editing" (click)="cd.reject()" autofocus></button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>


<!-- Install Google Chrome Popup Message -->
<p-dialog [(visible)]="!isChrome" [responsive]="true" showEffect="fade" [modal]="true" [style]="{'width':'650px'}" [closable]="false" autoLayout="true"
(onHide)="onHideIsChrome()">
    <div class="row">
        <div class="col-xs-3">
            <a href="https://www.google.com/chrome/" target="_blank">
                <img class="logo" height="100" width="100" src="assets/images/main/google-chrome-icon.png" alt="Google Chrome">
            </a>
        </div>
        <div class="col-xs-9" style="padding-top: 1em; padding-left: 1em">
            <label class="modal-label-black">Number Picker application only works in Google Chrome.</label>
            <br><br>
            <a pButton class="basic-button" href="https://www.google.com/chrome/">
                <i class="fa fa-download" style="margin:4px 4px 0 0;"></i> Download
            </a>
        </div>
    </div>
</p-dialog>
