import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../_utils';
import { QuoteSession } from '../../../quote/quote.session';
import { SelectItem } from 'primeng/api';
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-filter-user',
  templateUrl: './filter-user.component.html',
  styleUrls: ['./filter-user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FilterUserComponent extends BaseComponent implements OnInit {
  private users: SelectItem [] = [];
  public selectedUser: any;
  public filteredUsers: any [] = [];

  constructor(
    private cd: ChangeDetectorRef,
      private _quoteSession: QuoteSession,
      public _angulartics: Angulartics2
  ) { 
    super();
  }

  ngOnInit() {
    this.initUsers();
    this.onSearchTableChange();
  }


  private initUsers(): void {
    this.users = this._quoteSession.users;
      this.selectedUser = this._quoteSession.defaultUser;

      this._angulartics.setUserProperties.next({ userId: this.selectedUser });
      this._angulartics.setUserProperties.next({ dimension1: this.selectedUser });
      this._angulartics.setUsername.next(this.selectedUser);
  }


  private onSearchTableChange(): void {
    this._quoteSession.searchTableChanged
      .subscribe( user => {
        this.users = this._quoteSession.users;
        this.selectedUser = user;
    });
  }


  public onCompleteMethod(event: any): void {
    this.users = this._quoteSession.users;
    let filtered: any [] = [];
    filtered.push("All Users");
    for(let i = 0; i < this.users.length; i++) {
      let user: string = this.users[i].toString();
      if(user.toLowerCase().indexOf(event.query.toLowerCase()) >= 0) {
          filtered.push(user);
      }
    }
    if( filtered.length == 1 ){
      filtered = [];
    }
    else if( filtered.length == 2 ){
      this.selectedUser = filtered[1];
      filtered = filtered.splice(1, 1);
    }
    this.filteredUsers = filtered;
  }


    public onSelect(user: string) {
        this._angulartics.eventTrack.next({ action: 'Search Tab-User Filter Changed', properties: { category:'User Selected:' + user, label: this._quoteSession.defaultUser, value: 2} });
        this._quoteSession.selectedUserChanged.emit(user);
  }
}
