<p-toast life="4000"></p-toast>

<!-- Add More Button -->   
<div *ngIf="displayMultipleNumberEdition" class="row" style="margin-top: 2em">
  <div class="col-xs-12 col-xs-offset-5">
      <button type="button" class="basic-button" pButton icon="fa fa-plus" (click)="onClickAddMoreBtn();" label="Add More"></button>
  </div>
</div>

<!-- Multiple Addition Table -->   
<div *ngIf="displayMultipleNumberEdition" class="p-datatable-resolution">
  <p-table #dt [columns]="cols" [value]="multipleNumberEditionList" [paginator]="true" [rows]="20" sortMode="multiple" responsive="true" 
            selectionMode="single" [(selection)]="selectedRow" (onRowSelect)="onRowSelect($event.data)"dataKey="numberId">
      <ng-template pTemplate="caption">
          <ng-template ptTemplate = "header" style="font-size: 1.6em; font-weight:bold;">List of Newly Assigned Numbers</ng-template>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
            <th style="width: 7em" [pSortableColumn]="'formattedNumber'">Number <p-sortIcon [field]="'formattedNumber'"></p-sortIcon></th>
            <th style="width: 7.5em" [pSortableColumn]="'formattedEngineeringRequestNumber'">ER <p-sortIcon [field]="'formattedEngineeringRequestNumber'"></p-sortIcon></th>
            <th [pSortableColumn]="'description'">Description <p-sortIcon [field]="'description'"></p-sortIcon></th>
            <th style="width: 11em" [pSortableColumn]="'majorProductCode'">MPC <p-sortIcon [field]="'majorProductCode'"></p-sortIcon></th>
            <th *ngIf="hasSerialNumber" style="width: 9.5em" [pSortableColumn]="'serialNumber'">Serial Number <p-sortIcon [field]="'serialNumber'"></p-sortIcon></th>
        </tr>
    </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [ngClass]="rowData.isEdited ? 'edited-row' : unedited-row" [pSelectableRow]="rowData">
            <td>
                {{rowData.formattedNumber}}
            </td>
            <td>
                {{rowData.formattedEngineeringRequestNumber}}
            </td>
            <td pEditableColumn="rowData" [ngClass]="rowData.isEdited ? 'edited-row' : 'unedited-row'">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <textarea rows="2" cols="75" maxlength="150" type="text" [(ngModel)]="rowData.description" (ngModelChange)="onNgModelChange(rowData);" style="resize: none;"></textarea>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.description}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td pEditableColumn="rowData" [pEditableColumnField]="'majorProductCode'" [ngClass]="rowData.isEdited ? 'edited-row' : 'unedited-row'">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                            <div class="grid">
                                <div class="col-12 md:col-12">
                                    <app-filter-mpc 
                                        [selectedMPC]="rowData.majorProductCode" [selectedERNumber]="rowData.formattedEngineeringRequestNumber" [rowData]="rowData" [table]="dt"
                                        [modifiedRowDataList]="modifiedRowDataList" [originalRowDataList]="originalRowDataList" [displayModifiedRows]="false">
                                    </app-filter-mpc>
                                </div>
                            </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData.majorProductCode}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td *ngIf="rowData.serialNumber">
                {{rowData.serialNumber}}
            </td>
          </tr>
      </ng-template>

      <ng-template pTemplate="summary" let-rowData>
          <div style="text-align:left">
              <button type="button" class="basic-button" pButton icon="fa fa-check" (click)="updateNewNumbers()" label="Save Changes"></button>
          </div>
      </ng-template>
  </p-table>
</div>
