export class _MajorProductCode {
    mpcId: number;
    MPC: string;
    createdBy: string;
    createdUtc: string;

    constructor(mpcId: number, m: string, createdBy: string, createdUtc: string){  
        this.mpcId = mpcId;
        this.MPC = m;
        this.createdBy = createdBy;
        this.createdUtc = createdUtc;
    }
}