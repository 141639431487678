import { Component, Input, Inject, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { PageScrollConfig, PageScrollService, PageScrollInstance } from 'ngx-page-scroll-core';
@Component({
    selector: 'validation-summary',
    templateUrl: './validation-summary.component.html',
    styleUrls: ['validation-summary.component.css']
})
export class ValidationSummaryComponent {
    @Input()
    container: ElementRef;
    @Input()
    controls;

    constructor(private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
    }
    allValid() {
        if (this.controls)
            return this.controls
                .map(x => x.control.valid)
                .every(this.isTrue);
        return false;
    }
    isTrue(item) {
        return item == true;
    }
    public scrollToTag(classToScrollTo: any): void {
        const pageScrollInstance: PageScrollInstance = new PageScrollInstance({document:this.document, scrollTarget: classToScrollTo});
        this.pageScrollService.start(pageScrollInstance);
    };
}