import { HttpClient } from '@angular/common/http';
import { EventEmitter, Output, Injectable, Directive } from '@angular/core';
import { Environment } from "./environment";
import { Configuration } from "./configuration";

@Directive()
@Injectable()
export class ConfigService {
    @Output() 
    changed: EventEmitter<any> = new EventEmitter<any>(true);

    @Output() errorOccured: EventEmitter<any> = new EventEmitter<any>(true);
    public serverError: string = "";

    public config: Configuration = new Configuration();
    public environment: Environment = new Environment();

    constructor(private http: HttpClient) {
        this.http.get('assets/data/config.json').subscribe(res => {
            this.config = res;
            this.environment = new Environment(this.config.env);
            //console.log('done loading config');
            this.changed.emit();
        });
    }

}
